import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { htmlSpecialsEncode } from '../../../utils/generalUtils';

import PageHeading from '../../../layout/PageHeading/PageHeading';
import BottomShadow from '../../../layout/BottomShadow/BottomShadow';
import './ProdFuzzyMatch.css';

import ProdFuzzyMatchSingleProd from './ProdFuzzyMatchSingleProd';
import ProdFuzzyMatchBulkMatch from './ProdFuzzyMatchBulkMatch';
import ProdFuzzyMatchSingleOverlay from './ProdFuzzyMatch_singleOverlay';

import {
  searchAsType,
  clearSearchAsType,
  hideAsType,
  searchFullText,
  searchQtyOnHand,
  getP21PriceAvail,
  getProductDetailsForPopup,
  recordItemClickAnalytics,
  recordSearchAnalyticFeedback,
} from '../../../../actions/search';
import {
  submitBulkFuzzy,
  errorBulkFuzzy,
  getBulkFuzzyProjects,
  deleteBulkFuzzyProject,
} from '../../../../actions/bulkFuzzyMatch';
import {
  searchAsType_company,
  clearSearchAsType_company,
  hideAsType_company,
} from '../../../../actions/searchCompany';
import {
  searchAsType_shipto,
  clearSearchAsType_shipto,
  hideAsType_shipto,
} from '../../../../actions/searchShipTo';

const ProdFuzzyMatch = ({
  searchAsType,
  clearSearchAsType,
  hideAsType,
  searchFullText,
  searchQtyOnHand,
  getP21PriceAvail,
  search,
  bulkFuzzyMatchState,
  submitBulkFuzzy,
  errorBulkFuzzy,
  getBulkFuzzyProjects,
  deleteBulkFuzzyProject,
  searchAsType_company,
  clearSearchAsType_company,
  hideAsType_company,
  searchCompany,
  searchAsType_shipto,
  searchShipTo,
  clearSearchAsType_shipto,
  hideAsType_shipto,
  getProductDetailsForPopup,
  recordItemClickAnalytics,
  recordSearchAnalyticFeedback,
  match,
}) => {
  const [detailOverlay, setDetailOverlay] = useState({
    display: false,
  });
  //const [searchBar, setSearchBar] = useState('');
  const [singleSearchFormData, setSingleSearchFormData] = useState({
    pricingCustomerID: '115239',
    pricingCustomerName: 'Cash Sales Tacoma',
    pricingShipToID: '115239',
    pricingShipToName: 'Cash Sales Tacoma',
    searchTerm: '',
    searchBarPartNumber: '',
    pag: 0,
  });
  const [searchAnalytics, setSearchAnalytics] = useState({
    analyticsSubmitted: false,
  });

  const [bulkFormData, setBulkFormData] = useState({
    bulkCustomerID: '',
    bulkCustomerName: '',
    bulkShipToID: '',
    bulkShipToName: '',
    priceBulkResults: '0',
    searchLoose: 'true',
    stockableFilter: 'false',
    showAdvancedOptions: true,
  });
  // eslint-disable-next-line
  const [bulkFormError, setBulkFormError] = useState('');
  const searchAsTypeKeyName = 'emp_prodfuzzymatch';
  const searchAsType_singleFuzzyMatch_companyKeyName =
    'emp_singleFuzzyMatch_company';
  const searchAsType_singleFuzzyMatch_shiptoKeyName =
    'emp_singleFuzzyMatch_shipto';
  const searchAsType_bulkFuzzyMatch_companyKeyName =
    'emp_bulkFuzzyMatch_company';
  const searchAsType_bulkFuzzyMatch_shiptoKeyName = 'emp_bulkFuzzyMatch_shipto';

  //const { searchURLTerm = '' } = match.params;
  const {
    emp_prodfuzzymatch = {},
    emp_prodfuzzy_fulltextresult = {},
    emp_prodfuzzy_fulltext_p21Info = {},
  } = search;
  const {
    tableLoading = true,
    tableData = [],
    formSuccessID = '',
  } = bulkFuzzyMatchState;
  const {
    emp_singleFuzzyMatch_company = {},
    emp_bulkFuzzyMatch_company = {},
  } = searchCompany;
  const {
    emp_singleFuzzyMatch_shipto = {},
    emp_bulkFuzzyMatch_shipto = {},
  } = searchShipTo;
  const { asYouTypeArray = [], showAsYouType = false } = emp_prodfuzzymatch;
  const {
    showAsYouType: showAsYouType_companySingle = false,
  } = emp_singleFuzzyMatch_company;
  const {
    showAsYouType: showAsYouType_shiptoSingle = false,
  } = emp_singleFuzzyMatch_shipto;

  const {
    showAsYouType: showAsYouType_companyBulk = false,
  } = emp_bulkFuzzyMatch_company;
  const {
    showAsYouType: showAsYouType_shiptoBulk = false,
  } = emp_bulkFuzzyMatch_shipto;

  const { formLoading = false } = bulkFuzzyMatchState;
  const { searchAnalyticsID = '' } = emp_prodfuzzy_fulltextresult;

  //effect to keep reloading the table data at a set interval if a project is still processing
  useEffect(() => {
    if (
      !tableLoading &&
      tableData.length > 0 &&
      tableData.find((x) => x.status === 'processing') !== undefined
    ) {
      setTimeout(() => {
        getBulkFuzzyProjects(false);
      }, 180000);
    }
    // eslint-disable-next-line
  }, [tableData, getBulkFuzzyProjects]);

  //initial load to get the table data for the bulk fuzzy projects
  //also refreshes on a successful project upload
  useEffect(() => {
    getBulkFuzzyProjects(true);
  }, [getBulkFuzzyProjects, formSuccessID]);

  //fetch the qty on hand out of P21 when we get a new results list
  useEffect(() => {
    if (
      emp_prodfuzzy_fulltextresult !== null &&
      emp_prodfuzzy_fulltextresult !== undefined &&
      emp_prodfuzzy_fulltextresult.searchResultsArr !== null &&
      emp_prodfuzzy_fulltextresult.searchResultsArr !== undefined
    ) {
      searchQtyOnHand(
        'emp_prodfuzzy_fulltext_p21Info',
        emp_prodfuzzy_fulltextresult.searchResultsArr
      );
    }
    // eslint-disable-next-line
  }, [emp_prodfuzzy_fulltextresult]);

  //when we get a form success ID then clear out the form to make way for another upload
  useEffect(() => {
    setBulkFormData({
      bulkCustomerID: bulkFormData.bulkCustomerID,
      bulkCustomerName: bulkFormData.bulkCustomerName,
      bulkShipToID: bulkFormData.bulkShipToID,
      bulkShipToName: bulkFormData.bulkShipToName,
      priceBulkResults: bulkFormData.priceBulkResults,
    });
    // eslint-disable-next-line
  }, [formLoading]);

  //reset the analytics state when the submission happens

  //effect to load the fuzzy match project list after a successful upload of a new project
  // eslint-disable-next-line
  const getTimeoutBulkFuzzyData = (initial = false) => {
    setTimeout(() => {
      getBulkFuzzyProjects(initial);
      getTimeoutBulkFuzzyData(false);
    }, 2000);
  };

  const onSingleSearchChange = (e) => {
    let newValue = e.target.value.replace('#', 'NO');
    setSingleSearchFormData({
      ...singleSearchFormData,
      [e.target.name]: newValue,
    });

    searchAsType(
      searchAsTypeKeyName,
      htmlSpecialsEncode(newValue),
      e.target.name
    );
  };

  const onClickAsYouTypeDiv = (searchTerm) => {
    setSingleSearchFormData({ searchBar: searchTerm });
    clearSearchAsType(searchAsTypeKeyName);
    //setSearchBar('');
    document.activeElement.blur();
    hideAsType(searchAsTypeKeyName);
    searchFullText(
      'emp_prodfuzzy_fulltextresult',
      singleSearchFormData,
      searchAnalyticsID,
      true,
      1
    );

    setSingleSearchFormData({
      ...singleSearchFormData,
      pag: 1,
    });

    setSearchAnalytics({
      ...searchAnalytics,
      analyticsSubmitted: false,
    });
  };

  const onSingleSearchKeyPress = (e) => {
    const { searchBar = '', searchBarPartNumber = '' } = singleSearchFormData;
    if (e.key === 'Enter') {
      if (searchBar.length > 1 || searchBarPartNumber.length > 1) {
        document.activeElement.blur();
        hideAsType(searchAsTypeKeyName);
        searchFullText(
          'emp_prodfuzzy_fulltextresult',
          singleSearchFormData,
          searchAnalyticsID,
          true,
          1
        );

        setSingleSearchFormData({
          ...singleSearchFormData,
          pag: 1,
        });

        setSearchAnalytics({
          ...searchAnalytics,
          analyticsSubmitted: false,
        });
      }
    }
  };

  const onChangeSingle_CompanyIDAsYouType = (e) => {
    let newValue = e.target.value.replace('#', 'NO');
    //console.log('onSingleSearch_CompanyIDAsYouType');

    setSingleSearchFormData({
      ...singleSearchFormData,
      [e.target.name]: newValue,
    });

    searchAsType_company(
      searchAsType_singleFuzzyMatch_companyKeyName,
      htmlSpecialsEncode(newValue)
    );
  };

  const onChangeSingle_ShipToAsYouType = (e) => {
    let newValue = e.target.value.replace('#', 'NO');
    //console.log('onSingleSearch_CompanyIDAsYouType');

    setSingleSearchFormData({
      ...singleSearchFormData,
      [e.target.name]: newValue,
    });

    searchAsType_shipto(
      searchAsType_singleFuzzyMatch_shiptoKeyName,
      htmlSpecialsEncode(newValue),
      singleSearchFormData.pricingCustomerID
    );
  };

  const onClickSingleAsYouTypeDiv_CompanyID = (companyName, p21_customerID) => {
    setSingleSearchFormData({
      ...singleSearchFormData,
      pricingCustomerName: companyName,
      pricingCustomerID: p21_customerID,
      pricingShipToName: '',
      pricingShipToID: '',
    });
    clearSearchAsType_company(searchAsType_singleFuzzyMatch_companyKeyName);
    //setSearchBar('');
    document.activeElement.blur();
    hideAsType_company(searchAsType_singleFuzzyMatch_companyKeyName);
  };

  const onClickSingleAsYouTypeDiv_ShipToID = (shipToName, p21_shipToID) => {
    setSingleSearchFormData({
      ...singleSearchFormData,
      pricingShipToName: shipToName,
      pricingShipToID: p21_shipToID,
    });
    clearSearchAsType_shipto(searchAsType_singleFuzzyMatch_shiptoKeyName);
    //setSearchBar('');
    document.activeElement.blur();
    hideAsType_shipto(searchAsType_singleFuzzyMatch_shiptoKeyName);
  };

  const onClickSearchButton = (e) => {
    const { searchBar = '', searchBarPartNumber = '' } = singleSearchFormData;
    if (searchBar.length <= 1 && searchBarPartNumber.length <= 1) return;

    document.activeElement.blur();
    hideAsType(searchAsTypeKeyName);
    searchFullText(
      'emp_prodfuzzy_fulltextresult',
      singleSearchFormData,
      searchAnalyticsID,
      true,
      1
    );

    setSingleSearchFormData({
      ...singleSearchFormData,
      pag: 1,
    });

    setSearchAnalytics({
      ...searchAnalytics,
      analyticsSubmitted: false,
    });
  };

  const onPaginationClick = (pag) => {
    document.activeElement.blur();
    hideAsType(searchAsTypeKeyName);

    setSingleSearchFormData({
      ...singleSearchFormData,
      pag,
    });

    searchFullText(
      'emp_prodfuzzy_fulltextresult',
      singleSearchFormData,
      searchAnalyticsID,
      false,
      pag
    );
  };

  const toggleSingleAdvancedOptions = () => {
    setSingleSearchFormData({
      ...singleSearchFormData,
      showAdvancedOptions: !singleSearchFormData.showAdvancedOptions,
    });
  };

  //event bubbled escape to close the as you type div
  const closeAsYouTypeDiv = (e, action) => {
    if (e.target.name === 'filePath' || e.target.name === 'submit') {
      console.log(e.target.name);
    } else {
      //only do something if the showAsYouType is being displayed
      if (showAsYouType) {
        if (e.target.name === 'search') {
        } else {
          e.preventDefault();
          hideAsType(searchAsTypeKeyName);
        }
      }
      if (showAsYouType_companySingle) {
        if (
          e.target.name === 'pricingCustomerName' ||
          e.target.name === 'pricingCustomerID'
        ) {
        } else {
          e.preventDefault();
          hideAsType_company(searchAsType_singleFuzzyMatch_companyKeyName);
        }
      }
      if (showAsYouType_shiptoSingle) {
        if (
          e.target.name === 'pricingShipToName' ||
          e.target.name === 'pricingShipToID'
        ) {
        } else {
          e.preventDefault();
          hideAsType_shipto(searchAsType_singleFuzzyMatch_shiptoKeyName);
        }
      }

      if (showAsYouType_companyBulk) {
        if (
          e.target.name === 'bulkCustomerName' ||
          e.target.name === 'bulkCustomerID'
        ) {
        } else {
          e.preventDefault();
          hideAsType_company(searchAsType_bulkFuzzyMatch_companyKeyName);
        }
      }
      if (showAsYouType_shiptoBulk) {
        if (
          e.target.name === 'bulkShipToName' ||
          e.target.name === 'bulkShipToID'
        ) {
        } else {
          e.preventDefault();
          hideAsType_shipto(searchAsType_bulkFuzzyMatch_shiptoKeyName);
        }
      }
    }
  };

  const onClickDeleteBulkFuzzyProject = (e) => {
    deleteBulkFuzzyProject(e.target.dataset.id);
  };

  const onChangeSingleSearchFormData = (e) => {
    setSingleSearchFormData({
      ...singleSearchFormData,
      [e.target.name]: e.target.value,
    });
  };

  const onMouseUpSingleSearchResultItem = (
    e,
    item_id,
    inv_mast_uid,
    _id,
    uiOpen
  ) => {
    var text = '';
    if (typeof window.getSelection != 'undefined') {
      text = window.getSelection().toString();
    } else if (
      typeof document.selection != 'undefined' &&
      document.selection.type === 'Text'
    ) {
      text = document.selection.createRange().text;
    }

    //if no text was selected, then get price/Avail
    if (text === '' && e.button === 0) {
      //record our item click in analytics
      recordItemClickAnalytics(searchAnalyticsID, _id);

      //get the detail overlay information for our product
      getProductDetailsForPopup('emp_prodfuzzy_fulltext_productdetails', _id);

      //display our overlay
      setDetailOverlay({
        ...detailOverlay,
        display: true,
      });

      //make sure we have a customer and shipToID
      if (
        singleSearchFormData.pricingCustomerID !== null &&
        singleSearchFormData.pricingShipToID !== null &&
        inv_mast_uid !== ''
      ) {
        //preload our pricing and availability for the product
        getP21PriceAvail(
          'emp_prodfuzzy_fulltext_p21Info',
          item_id,
          inv_mast_uid,
          singleSearchFormData.pricingCustomerID,
          singleSearchFormData.pricingShipToID,
          1
        );
      } else {
        //console.log('No Customer/ShipToID present, unable to pull');
      }
    }
  };

  const onChangeInputBulk = (e) => {
    setBulkFormData({ ...bulkFormData, [e.target.name]: e.target.value });
  };

  const onChangeBulk_CompanyIDAsYouType = (e) => {
    let newValue = e.target.value.replace('#', 'NO');
    //console.log('onSingleSearch_CompanyIDAsYouType');

    setBulkFormData({
      ...bulkFormData,
      [e.target.name]: newValue,
    });

    searchAsType_company(
      searchAsType_bulkFuzzyMatch_companyKeyName,
      htmlSpecialsEncode(newValue)
    );
  };

  const onClickBulkAsYouTypeDiv_CompanyID = (companyName, p21_customerID) => {
    setBulkFormData({
      ...bulkFormData,
      bulkCustomerName: companyName,
      bulkCustomerID: p21_customerID,
      bulkShipToName: '',
      bulkShipToID: '',
    });
    clearSearchAsType_company(searchAsType_bulkFuzzyMatch_companyKeyName);
    //setSearchBar('');
    document.activeElement.blur();
    hideAsType_company(searchAsType_bulkFuzzyMatch_companyKeyName);
  };

  const onChangeBulk_ShipToAsYouType = (e) => {
    let newValue = e.target.value.replace('#', 'NO');
    //console.log('onSingleSearch_CompanyIDAsYouType');

    setBulkFormData({
      ...bulkFormData,
      [e.target.name]: newValue,
    });

    searchAsType_shipto(
      searchAsType_bulkFuzzyMatch_shiptoKeyName,
      htmlSpecialsEncode(newValue),
      bulkFormData.bulkCustomerID
    );
  };

  const onClickBulkAsYouTypeDiv_ShipToID = (shipToName, p21_shipToID) => {
    setBulkFormData({
      ...bulkFormData,
      bulkShipToName: shipToName,
      bulkShipToID: p21_shipToID,
    });
    clearSearchAsType_shipto(searchAsType_bulkFuzzyMatch_shiptoKeyName);
    //setSearchBar('');
    document.activeElement.blur();
    hideAsType_shipto(searchAsType_bulkFuzzyMatch_shiptoKeyName);
  };

  const detailOverlay_hide = (e) => {
    if (
      e.target.dataset === null ||
      e.target.dataset === undefined ||
      e.target.dataset.closeoverlay === null ||
      e.target.dataset.closeoverlay === undefined
    )
      return;
    if (e.target.dataset.closeoverlay === 'true') {
      setDetailOverlay({
        ...detailOverlay,
        display: false,
      });
    }
  };

  const searchFeedbackOnClick = (matchFound = true) => {
    recordSearchAnalyticFeedback(searchAnalyticsID, matchFound);

    setSearchAnalytics({
      ...searchAnalytics,
      analyticsSubmitted: true,
    });
  };

  return (
    <Fragment>
      {detailOverlay.display && (
        <ProdFuzzyMatchSingleOverlay
          detailOverlay_hide={detailOverlay_hide}
          search_state={search}
          searchFeedbackOnClick={searchFeedbackOnClick}
          searchAnalytics={searchAnalytics}
        />
      )}
      <div id='ProdFuzzyMatch' onClick={(e) => closeAsYouTypeDiv(e)}>
        <div className='centerContainer'>
          <PageHeading
            config={{
              preHeading: 'Research Tool',
              heading: 'Product ID Helper',
              description:
                'Search for a product in P21 by describing an item using plain english or a part number. This tool accesses all 1.8 million P21 products and returns the most probable match suggestions, based on the description entered.',
              graphic: 'fas fa-list-alt',
            }}
          />
          <div className='contentBox singleProduct'>
            <ProdFuzzyMatchSingleProd
              onSearchChange={onSingleSearchChange}
              onSearchKeyPress={onSingleSearchKeyPress}
              onClickSearchButton={onClickSearchButton}
              showAsYouType={showAsYouType}
              singleSearchFormData={singleSearchFormData}
              //searchBar={searchBar}
              onChangeSingleSearchFormData={onChangeSingleSearchFormData}
              asYouTypeArray={asYouTypeArray}
              onClickAsYouTypeDiv={onClickAsYouTypeDiv}
              onMouseUpSingleSearchResultItem={onMouseUpSingleSearchResultItem}
              emp_prodfuzzy_fulltextresult={emp_prodfuzzy_fulltextresult}
              emp_prodfuzzy_fulltext_p21Info={emp_prodfuzzy_fulltext_p21Info}
              onChangeSingle_CompanyIDAsYouType={
                onChangeSingle_CompanyIDAsYouType
              }
              emp_singleFuzzyMatch_company={emp_singleFuzzyMatch_company}
              onClickSingleAsYouTypeDiv_CompanyID={
                onClickSingleAsYouTypeDiv_CompanyID
              }
              onChangeSingle_ShipToAsYouType={onChangeSingle_ShipToAsYouType}
              emp_singleFuzzyMatch_shipto={emp_singleFuzzyMatch_shipto}
              onClickSingleAsYouTypeDiv_ShipToID={
                onClickSingleAsYouTypeDiv_ShipToID
              }
              toggleSingleAdvancedOptions={toggleSingleAdvancedOptions}
              searchFeedbackOnClick={searchFeedbackOnClick}
              searchAnalytics={searchAnalytics}
              onPaginationClick={onPaginationClick}
            />
          </div>
          <BottomShadow />

          <div className='contentBox uploadBulk'>
            <ProdFuzzyMatchBulkMatch
              bulkFormData={bulkFormData}
              onChangeInputBulk={onChangeInputBulk}
              bulkFormError={bulkFormError}
              bulkFuzzyMatchState={bulkFuzzyMatchState}
              submitBulkFuzzy={submitBulkFuzzy}
              errorBulkFuzzy={errorBulkFuzzy}
              onClickDeleteBulkFuzzyProject={onClickDeleteBulkFuzzyProject}
              onChangeBulk_CompanyIDAsYouType={onChangeBulk_CompanyIDAsYouType}
              emp_bulkFuzzyMatch_company={emp_bulkFuzzyMatch_company}
              onClickBulkAsYouTypeDiv_CompanyID={
                onClickBulkAsYouTypeDiv_CompanyID
              }
              emp_bulkFuzzyMatch_shipto={emp_bulkFuzzyMatch_shipto}
              onChangeBulk_ShipToAsYouType={onChangeBulk_ShipToAsYouType}
              onClickBulkAsYouTypeDiv_ShipToID={
                onClickBulkAsYouTypeDiv_ShipToID
              }
            />
          </div>
          <BottomShadow />
        </div>
      </div>
    </Fragment>
  );
};

ProdFuzzyMatch.propTypes = {
  searchAsType: PropTypes.func.isRequired,
  clearSearchAsType: PropTypes.func.isRequired,
  hideAsType: PropTypes.func.isRequired,
  searchFullText: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  search: state.search,
  bulkFuzzyMatchState: state.bulkFuzzyMatch,
  searchCompany: state.searchCompany,
  searchShipTo: state.searchShipTo,
});

export default connect(mapStateToProps, {
  searchAsType,
  clearSearchAsType,
  hideAsType,
  searchFullText,
  searchQtyOnHand,
  submitBulkFuzzy,
  errorBulkFuzzy,
  getBulkFuzzyProjects,
  deleteBulkFuzzyProject,
  getP21PriceAvail,
  searchAsType_company,
  clearSearchAsType_company,
  hideAsType_company,
  searchAsType_shipto,
  clearSearchAsType_shipto,
  hideAsType_shipto,
  getProductDetailsForPopup,
  recordItemClickAnalytics,
  recordSearchAnalyticFeedback,
})(withRouter(ProdFuzzyMatch));
