import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import PageHeading from "../../../layout/PageHeading/PageHeading";

import "../../../layout/CustomCSS/fancyToggle.scss";

const AdminDashboard = ({}) => {
  return (
    <div id="adminDashboard">
      <div className="centerContainer">
        <PageHeading
          config={{
            preHeading: "Restricted Access Area",
            heading: "Admin Dashboard",
            description: "",
            graphic: "fas fa-user-shield",
          }}
        />
        <div className="contentBox">
          <Link to="/admin/allusers">Manage All Users</Link>
        </div>
      </div>
    </div>
  );
};

/*
<label>
            <input type="checkbox" value="1" className="fancy-toggle" />
            <i></i>
            <span>Work</span>
          </label>
          */

AdminDashboard.propTypes = {};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(AdminDashboard);
