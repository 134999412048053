import {
  SEARCH_COMPANY_ASTYPE_RESULT,
  SEARCH_COMPANY_ASTYPE_CLEAR,
  SEARCH_COMPANY_ASTYPE_SHOW,
  SEARCH_COMPANY_ASTYPE_HIDE,
} from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_COMPANY_ASTYPE_RESULT:
      return {
        ...state,
        [payload.keyName]: {
          asYouTypeArray: payload.asYouTypeArray,
          showAsYouType: true,
          loading: false,
        },
      };
    case SEARCH_COMPANY_ASTYPE_CLEAR:
      return {
        ...state,
        [payload.keyName]: {
          asYouTypeArray: [],
          showAsYouType: false,
          loading: false,
        },
      };
    case SEARCH_COMPANY_ASTYPE_SHOW:
      return {
        ...state,
        [payload.keyName]: {
          showAsYouType: true,
        },
      };
    case SEARCH_COMPANY_ASTYPE_HIDE:
      return {
        ...state,
        [payload.keyName]: {
          showAsYouType: false,
        },
      };
    default:
      return state;
  }
}
