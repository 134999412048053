import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import ProdFuzzyMatchSingleProdResults from './ProdFuzzyMatch_singleProdResults';

const ProdFuzzyMatchSingleProd = ({
  onSearchChange,
  onSearchKeyPress,
  onClickSearchButton,
  showAsYouType,
  //searchBar,
  singleSearchFormData,
  onChangeSingleSearchFormData,
  asYouTypeArray,
  onClickAsYouTypeDiv,
  onMouseUpSingleSearchResultItem,
  emp_prodfuzzy_fulltextresult,
  emp_prodfuzzy_fulltext_p21Info,
  onChangeSingle_CompanyIDAsYouType,
  emp_singleFuzzyMatch_company,
  onClickSingleAsYouTypeDiv_CompanyID,
  onChangeSingle_ShipToAsYouType,
  emp_singleFuzzyMatch_shipto,
  onClickSingleAsYouTypeDiv_ShipToID,
  toggleSingleAdvancedOptions,
  searchFeedbackOnClick,
  searchAnalytics,
  onPaginationClick,
}) => {
  const {
    searchBar = '',
    searchBarPartNumber = '',
    adStarsSearchFilter = 'starsandad_preferred',
    pricingCustomerID = '115239',
    pricingShipToID = '115239',
    pricingCustomerName = '',
    pricingShipToName = '',
    searchLoose = true,
    stockableFilter = false,
    showAdvancedOptions = false,
  } = singleSearchFormData;

  const {
    asYouTypeArray: asYouTypeArray_companySingle = [],
    showAsYouType: showAsYouType_companySingle = false,
  } = emp_singleFuzzyMatch_company;

  const {
    asYouTypeArray: asYouTypeArray_shiptoSingle = [],
    showAsYouType: showAsYouType_shiptoSingle = false,
  } = emp_singleFuzzyMatch_shipto;

  return (
    <div className='content'>
      <div className='secTitle'>Search For Product Suggestions:</div>
      <div></div>
      <div className='singleProductSearch-outer'>
        <input
          type='text'
          name='searchBarPartNumber'
          value={searchBarPartNumber}
          placeholder='Part Number'
          id='searchBarPartNumber'
          onChange={(e) => onSearchChange(e)}
          onKeyPress={(e) => onSearchKeyPress(e)}
        />
        <input
          type='text'
          name='searchBar'
          value={searchBar}
          placeholder='Product Description'
          onChange={(e) => onSearchChange(e)}
          onClick={(e) => onSearchChange(e)}
          onKeyPress={(e) => onSearchKeyPress(e)}
        />
        <button onClick={(e) => onClickSearchButton(e)}>Search</button>
      </div>
      <div
        className={
          showAsYouType
            ? 'searchAsTypeAnchor-outer'
            : 'searchAsTypeAnchor-outer hide'
        }
      >
        {asYouTypeArray && asYouTypeArray.length > 0 && (
          <div className='searchAsTypeAnchor'>
            {asYouTypeArray.map((termRes) => {
              return (
                <div
                  key={uuidv4()}
                  onClick={(e) => onClickAsYouTypeDiv(termRes)}
                >
                  {termRes}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div
        className={
          showAdvancedOptions
            ? 'singleSearchAdvancedOptions-outer'
            : 'singleSearchAdvancedOptions-outer hide'
        }
      >
        <div className='pricingInfoInputs'>
          <div className='title'>Get Pricing Info For:</div>{' '}
          <div className='pricingInput-inner-item'>
            <div className='label'>Customer ID:</div>{' '}
            <input
              type='text'
              name='pricingCustomerID'
              value={pricingCustomerID}
              onChange={(e) => onChangeSingle_CompanyIDAsYouType(e)}
              style={{ width: '60px' }}
              autoComplete='off'
            />{' '}
            <input
              type='text'
              name='pricingCustomerName'
              value={pricingCustomerName}
              onChange={(e) => onChangeSingle_CompanyIDAsYouType(e)}
              className='fullWidth'
              autoComplete='off'
            />
          </div>
          <div
            className={
              showAsYouType_companySingle
                ? 'searchAsTypeAnchor-outer'
                : 'searchAsTypeAnchor-outer hide'
            }
          >
            {asYouTypeArray_companySingle &&
              asYouTypeArray_companySingle.length > 0 && (
                <div className='searchAsTypeAnchor'>
                  {Object.keys(asYouTypeArray_companySingle).map((keyName) => {
                    const {
                      companyName = '',
                      p21_companyID = '',
                    } = asYouTypeArray_companySingle[keyName];
                    return (
                      <div
                        key={uuidv4()}
                        onClick={(e) =>
                          onClickSingleAsYouTypeDiv_CompanyID(
                            companyName,
                            p21_companyID
                          )
                        }
                      >
                        {p21_companyID} - {companyName}
                      </div>
                    );
                  })}
                </div>
              )}
          </div>
          <div className='pricingInput-inner-item'>
            <div className='label'>ShipTo ID:</div>{' '}
            <input
              type='text'
              name='pricingShipToID'
              value={pricingShipToID}
              onChange={(e) => onChangeSingle_ShipToAsYouType(e)}
              onClick={(e) => onChangeSingle_ShipToAsYouType(e)}
              style={{ width: '60px' }}
              autoComplete='off'
            />
            <input
              type='text'
              name='pricingShipToName'
              value={pricingShipToName}
              onChange={(e) => onChangeSingle_ShipToAsYouType(e)}
              onClick={(e) => onChangeSingle_ShipToAsYouType(e)}
              className='fullWidth'
              autoComplete='off'
            />
          </div>
          <div
            className={
              showAsYouType_shiptoSingle
                ? 'searchAsTypeAnchor-outer'
                : 'searchAsTypeAnchor-outer hide'
            }
          >
            {asYouTypeArray_shiptoSingle &&
              asYouTypeArray_shiptoSingle.length > 0 && (
                <div className='searchAsTypeAnchor'>
                  {Object.keys(asYouTypeArray_shiptoSingle).map((keyName) => {
                    const {
                      shipToName = '',
                      p21_shipToID = '',
                    } = asYouTypeArray_shiptoSingle[keyName];
                    return (
                      <div
                        key={uuidv4()}
                        onClick={(e) =>
                          onClickSingleAsYouTypeDiv_ShipToID(
                            shipToName,
                            p21_shipToID
                          )
                        }
                      >
                        {p21_shipToID} - {shipToName}
                      </div>
                    );
                  })}
                </div>
              )}
          </div>
        </div>
        <div className='options-flex-outer'>
          <div className='options-flex'>
            <div className='label'>STARS & AD Search:</div>
            <select
              name='adStarsSearchFilter'
              value={adStarsSearchFilter}
              onChange={(e) => onChangeSingleSearchFormData(e)}
            >
              <option value='general'>Show All Products</option>
              <option value='starsandad_preferred'>STARS + AD Preferred</option>
              <option value='starsandad_only'>STARS + AD Only</option>
              <option value='stars_only'>STARS Only</option>
              <option value='ad_only'>AD Only</option>
            </select>
          </div>
          <div className='options-flex'>
            <div className='label'>Stockable Items</div>
            <select
              name='stockableFilter'
              value={stockableFilter}
              onChange={(e) => onChangeSingleSearchFormData(e)}
            >
              <option value='true'>Show Only Stockable Items</option>
              <option value='false'>Show All Items</option>
            </select>
          </div>
          <div className='options-flex'>
            <div className='label'>Search Strategy:</div>
            <select
              name='searchLoose'
              value={searchLoose}
              onChange={(e) => onChangeSingleSearchFormData(e)}
            >
              <option value='true'>Loose Matches</option>
              <option value='false'>Strict Matches</option>
            </select>
          </div>
        </div>
      </div>
      <div className='toggleAdvancedOptions'>
        <span onClick={() => toggleSingleAdvancedOptions()}>
          {showAdvancedOptions
            ? 'Hide Advanced Search Options'
            : 'Show Advanced Search Options'}
        </span>
      </div>
      <ProdFuzzyMatchSingleProdResults
        resultsObj={emp_prodfuzzy_fulltextresult}
        resultsP21={emp_prodfuzzy_fulltext_p21Info}
        onMouseUpSingleSearchResultItem={onMouseUpSingleSearchResultItem}
        searchFeedbackOnClick={searchFeedbackOnClick}
        searchAnalytics={searchAnalytics}
        onPaginationClick={onPaginationClick}
      />
    </div>
  );
};

ProdFuzzyMatchSingleProd.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  onSearchKeyPress: PropTypes.func.isRequired,
  onClickSearchButton: PropTypes.func.isRequired,
  showAsYouType: PropTypes.bool.isRequired,
  singleSearchFormData: PropTypes.object.isRequired,
  onChangeSingleSearchFormData: PropTypes.func.isRequired,
  asYouTypeArray: PropTypes.array.isRequired,
  onClickAsYouTypeDiv: PropTypes.func.isRequired,
  onMouseUpSingleSearchResultItem: PropTypes.func.isRequired,
  emp_prodfuzzy_fulltextresult: PropTypes.object.isRequired,
  onChangeSingle_CompanyIDAsYouType: PropTypes.func.isRequired,
  emp_singleFuzzyMatch_company: PropTypes.object.isRequired,
  onClickSingleAsYouTypeDiv_CompanyID: PropTypes.func.isRequired,
  onChangeSingle_ShipToAsYouType: PropTypes.func.isRequired,
  emp_singleFuzzyMatch_shipto: PropTypes.object.isRequired,
  onClickSingleAsYouTypeDiv_ShipToID: PropTypes.func.isRequired,
  toggleSingleAdvancedOptions: PropTypes.func.isRequired,
  searchFeedbackOnClick: PropTypes.func.isRequired,
  searchAnalytics: PropTypes.object.isRequired,
};

export default ProdFuzzyMatchSingleProd;
