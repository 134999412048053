import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./components/utils/setAuthToken";

//import our permission objects
import PrivateRoute from "./components/utils/PrivateRoute";
import AdminRoute from "./components/utils/AdminRoute";
import SolutionsRoute from "./components/utils/SolutionsRoute";

import "./App.css";
import "./TableDisplay.css";
import "./ContentBlock.css";
import "./TableDivCSS.css";

//Temporary Tools
import TempTools from "./components/pages/employeeOnly/tempTools/TempTools";

//Layout Componentsf
import Header from "./components/layout/Header/Header";
import Backdrop from "./components/layout/Backdrop/Backdrop";
import SideBar from "./components/layout/SideBar/SideBar";
import SideDrawer from "./components/layout/SideDrawer/SideDrawer";
import ScrollToTop from "./components/layout/ScrollToTop/ScrollToTop";
import Alert from "./components/layout/Alert";
import Version from "./components/layout/Version/Version";

//Overlay with Backgrounds
//import ProdFuzzyMatch_singeOverlay from './components/pages/employeeOnly/ProdFuzzyMatch/ProdFuzzyMatch_singleOverlay';

//Pages
import ProdFuzzyMatch from "./components/pages/employeeOnly/ProdFuzzyMatch/ProdFuzzyMatch";
import Dashboard from "./components/pages/employeeOnly/Dashboard/Dashboard";
import Login from "./components/pages/public/auth/Login";
import ForgotPassword from "./components/pages/public/auth/ForgotPassword";
import ResetPassword from "./components/pages/public/auth/ResetPassword";
import AccountSettings from "./components/pages/employeeOnly/UserProfile/AccountSettings/AccountSettings";

//Admin pages
import AdminDashboard from "./components/pages/adminOnly/AdminDashboard/AdminDashboard";
import AllUsers from "./components/pages/adminOnly/AllUsers/AllUsers";

// Check if user is logged in
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  let backdrop;

  const toggleDrawerClickHandler = () => {
    setSideDrawerOpen(true);
  };

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }

  //<ProdFuzzyMatch_singeOverlay />

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          {backdrop}
          <ScrollToTop />
          <SideBar />
          <SideDrawer show={sideDrawerOpen} click={backdropClickHandler} />

          <Version />
          <Header drawerClick={toggleDrawerClickHandler} />
          <Alert />
          <div id="contentWrapper">
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route
                exact
                path="/resetpassword/:resetCode"
                component={ResetPassword}
              />
              <PrivateRoute
                exact
                path="/employee/prodfuzzymatch"
                component={ProdFuzzyMatch}
              />
              <PrivateRoute
                exact
                path="/employee/prodfuzzymatch/:searchURLTerm"
                component={ProdFuzzyMatch}
              />
              <PrivateRoute
                exact
                path="/employee/dashboard"
                component={Dashboard}
              />
              <PrivateRoute
                exact
                path="/employee/accountsettings"
                component={AccountSettings}
              />
              <AdminRoute
                exact
                path="/admin/dashboard"
                component={AdminDashboard}
              />
              <AdminRoute exact path="/admin/allusers" component={AllUsers} />
            </Switch>
          </div>
        </Fragment>
      </Router>
    </Provider>
  );
}

export default App;
