import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../../layout/Spinner';

import './ProdFuzzyMatch_singleOverlay.css';
import ProdFuzzyMatchSingleOverlaySec1 from './ProdFuzzyMatch_singleOverlay_sec1';
import ProdFuzzyMatchSingleOverlaySec2 from './ProdFuzzyMatch_singleOverlay_sec2';
import ProdFuzzyMatchSingleOverlaySec3 from './ProdFuzzyMatch_singleOverlay_sec3';
import ProdFuzzyMatchSingleOverlaySec4 from './ProdFuzzyMatch_singleOverlay_sec4';

const ProdFuzzyMatch_singeOverlay = ({
  search_state = {},
  detailOverlay_hide,
  searchFeedbackOnClick,
  searchAnalytics = {},
}) => {
  const {
    emp_prodfuzzy_fulltext_p21Info = {},
    emp_prodfuzzy_fulltext_productdetails = {},
  } = search_state;

  const {
    p21Data = {},
    loading = true,
  } = emp_prodfuzzy_fulltext_productdetails;

  const { item_desc = '' } = p21Data;

  //setup some of our variables given not all records have all the data we want to display
  let prodTitle = item_desc;

  return (
    <div
      id='ProdFuzzyMatch_overlay'
      onClick={(e) => detailOverlay_hide(e)}
      data-closeoverlay='true'
    >
      <div id='ProdFuzzyMatch_overlay-inner'>
        <div className='topTitleBar'>
          <div className='topTitleBar-left'>
            {!loading && <Fragment>{prodTitle.toUpperCase()}</Fragment>}
          </div>
          <div
            className='topTitleBar-right'
            onClick={(e) => detailOverlay_hide(e)}
            data-closeoverlay='true'
          >
            Close
          </div>
        </div>
        {loading && <Spinner />}
        {!loading && (
          <Fragment>
            <ProdFuzzyMatchSingleOverlaySec1
              search_state={search_state}
              prodTitle={prodTitle}
              searchFeedbackOnClick={searchFeedbackOnClick}
              searchAnalytics={searchAnalytics}
            />
            <ProdFuzzyMatchSingleOverlaySec2 search_state={search_state} />
            <ProdFuzzyMatchSingleOverlaySec3 search_state={search_state} />
            <ProdFuzzyMatchSingleOverlaySec4
              search_state={search_state}
              p21Info={emp_prodfuzzy_fulltext_p21Info}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

ProdFuzzyMatch_singeOverlay.propTypes = {
  search_state: PropTypes.object.isRequired,
  detailOverlay_hide: PropTypes.func.isRequired,
  searchFeedbackOnClick: PropTypes.func.isRequired,
  searchAnalytics: PropTypes.object.isRequired,
};

export default ProdFuzzyMatch_singeOverlay;
