import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ProductImg from './ProductImg';

const ProductImgArea = ({ assets = [], name = '' }) => {
  //set our first img into the state, so we can
  //replace with a "click" on the other pics
  const [singleImg, setSingleImg] = useState(assets[0]);

  useEffect(() => {
    //singleImg is assets[0];
    setSingleImg(assets[0]);
  }, [assets]);
  //WAS assets[0]

  const onClick = (e) => {
    setSingleImg(assets[e.target.getAttribute('num')]);
  };

  //no images are in the record
  if (assets.length === 0) {
    //return default NO image pic
    return null;
    /*
    return (
      <div className='noImageFound'>
        <i className='far fa-images fa-2x'></i>
        <div>No Image Found</div>
      </div>
    );
    */
  }

  //only one asset to display
  if (assets.length === 1) {
    return (
      <div className='summary_pics-outer'>
        <div className='p_top_img_single'>
          <ProductImg assets={singleImg} altText={name} single='true' />
        </div>
      </div>
    );
  }

  //we have multiple images to display, show a full area to work with
  return (
    <div>
      <div className='p_top_img'>
        <div id='p_side_img_prew'>
          <ProductImg assets={assets} altText={name} onClick={onClick} />
        </div>
        <div id='p_side_img_main'>
          <ProductImg assets={singleImg} altText={name} single='true' />
        </div>
      </div>
    </div>
  );
};

ProductImgArea.propTypes = {
  assets: PropTypes.array.isRequired,
};

export default ProductImgArea;
