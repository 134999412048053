import {
  BULKFUZZY_FORM_LOADING,
  BULKFUZZY_FORM_ERROR,
  BULKFUZZY_FORM_SUCCESS,
  BULKFUZZY_FORM_RESET,
  BULKFUZZY_TABLE_LOADING,
  BULKFUZZY_TABLE_RESULT,
} from '../actions/types';

const initialState = {
  formLoading: false,
  formError: '',
  formSuccess: false,
  formSuccessID: '',
  tableLoading: false,
  tableData: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case BULKFUZZY_FORM_LOADING:
      return {
        ...state,
        formLoading: true,
        formError: '',
        formSuccess: false,
        formSuccessID: '',
      };
    case BULKFUZZY_FORM_ERROR:
      return {
        ...state,
        formLoading: false,
        formError: payload,
        formSuccess: false,
        formSuccessID: '',
      };
    case BULKFUZZY_FORM_SUCCESS:
      return {
        ...state,
        formLoading: false,
        formError: '',
        formSuccess: true,
        formSuccessID: payload,
      };
    case BULKFUZZY_FORM_RESET:
      return {
        ...state,
        formError: '',
        formSuccess: false,
        formSuccessID: '',
      };
    case BULKFUZZY_TABLE_LOADING:
      return {
        ...state,
        tableLoading: true,
        tableData: [],
      };
    case BULKFUZZY_TABLE_RESULT:
      return {
        ...state,
        tableLoading: false,
        tableData: payload,
      };
    default:
      return state;
  }
}
