import axios from 'axios';
import { setAlert } from './alert';

import {
  USERSETTINGS_LOADING,
  USERSETTINGS_CHANGEPASSWORD,
  USERSETTINGS_ERROR,
} from './types';

//Update our users password
export const modifyUserPassword = (profileState) => async (dispatch) => {
  const { oldPassword = '', password = '', verifyPassword = '' } = profileState;

  //get the inner stellar api token stored in the user table
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ oldPassword, password, verifyPassword });

  try {
    dispatch({ type: USERSETTINGS_LOADING });
    const res = await axios.put('/api/users/password', body, config);
    if (res.data.success) {
      dispatch({
        type: USERSETTINGS_CHANGEPASSWORD,
        payload: 'Password updated successfully',
      });
      dispatch(
        setAlert('Your password has successfully been changed', 'success')
      );
    } else {
      dispatch({
        type: USERSETTINGS_ERROR,
        payload: 'An error has occured, please try again later',
      });
    }
  } catch (err) {
    console.log(err);
    const errors = err.response.data.errors;

    if (errors) {
      dispatch({ type: USERSETTINGS_ERROR, payload: errors.pop().msg });
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};
