import axios from 'axios';
import {
  SEARCH_COMPANY_ASTYPE_RESULT,
  SEARCH_COMPANY_ASTYPE_CLEAR,
  SEARCH_COMPANY_ASTYPE_SHOW,
  SEARCH_COMPANY_ASTYPE_HIDE,
} from './types';

export const showAsType_company = (keyName) => async (dispatch) => {
  let payload = {
    keyName,
  };
  dispatch({ type: SEARCH_COMPANY_ASTYPE_SHOW, payload });
};

export const hideAsType_company = (keyName) => async (dispatch) => {
  let payload = {
    keyName,
  };
  dispatch({ type: SEARCH_COMPANY_ASTYPE_HIDE, payload });
};

export const searchAsType_company = (keyName = '', searchTerm = '') => async (
  dispatch
) => {
  let payload = {
    keyName,
    asYouTypeArray: [],
  };
  try {
    let res;

    if (searchTerm.length >= 2 && searchTerm.length < 25) {
      let searchTermModified = searchTerm.replace('#', 'NO');
      res = await axios.get(`/api/searchCompany/asType/${searchTermModified}`);

      //check what just came back, set a default if null, store in the payload
      const { asYouTypeArray = [] } = res.data;
      payload.asYouTypeArray = asYouTypeArray;
      dispatch({ type: SEARCH_COMPANY_ASTYPE_RESULT, payload });
    } else {
      dispatch({ type: SEARCH_COMPANY_ASTYPE_CLEAR, payload });
    }
  } catch (err) {
    dispatch({ type: SEARCH_COMPANY_ASTYPE_CLEAR, payload });
  }
};

export const clearSearchAsType_company = (keyName) => async (dispatch) => {
  let payload = {
    keyName,
  };
  dispatch({
    type: SEARCH_COMPANY_ASTYPE_CLEAR,
    payload,
  });
};
