import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../components/utils/setAuthToken';

import {
  //REGISTER_SUCCESS,
  //REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_RESETCODEVALID,
  USER_RESETCODEINVALID,
  USER_RESETCODELOADING,
} from './types';

// Login User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ email, password });
  try {
    const res = await axios.post('/api/auth', body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({ type: LOGIN_FAIL });
  }
};

/*
// REGISTER USER
export const register = ({ name, email, password }) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ name, email, password });

  try {
    const res = await axios.post('/api/users', body, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({ type: REGISTER_FAIL });
  }
};
*/

// LOAD User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get('/api/auth');

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const emailForgotPassword = (email = '') => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ email });
  try {
    // eslint-disable-next-line
    const res = await axios.post('/api/auth/forgotPassword', body, config);
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

export const checkResetCodeValid = (resetCode = '') => async (dispatch) => {
  dispatch({ type: USER_RESETCODELOADING });
  try {
    //this will throw an error if the code is invalid
    // eslint-disable-next-line
    const res = await axios.get(`/api/auth/checkResetCodeValid/${resetCode}`);

    dispatch({ type: USER_RESETCODEVALID });
  } catch (err) {
    //the invalid will kick the user out
    dispatch({ type: USER_RESETCODEINVALID });
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

export const resetPasswordWithCode = (
  resetCode = '',
  email = '',
  password = ''
) => async (dispatch) => {
  dispatch({ type: USER_RESETCODELOADING });
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ resetCode, email, password });

    const res = await axios.post('/api/auth/resetPassword', body, config);

    //eval if we got a valid user token back. If not then we must have failed in life :(
    if (
      res === null ||
      res === undefined ||
      res.data === null ||
      res.data === undefined ||
      res.data.token === null ||
      res.data.token === undefined ||
      res.data.token === ''
    ) {
      dispatch({ type: USER_RESETCODEINVALID });
      dispatch(
        setAlert(
          'Unable to reset your password. Please contact support.',
          'danger'
        )
      );
      return;
    }

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(
      setAlert('Your password has successfully been changed', 'success')
    );

    dispatch(loadUser());
  } catch (err) {
    dispatch({ type: USER_RESETCODEINVALID });
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({ type: LOGIN_FAIL });
  }
};
