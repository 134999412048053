import {
  SEARCH_ASTYPE_RESULT,
  SEARCH_ASTYPE_CLEAR,
  SEARCH_ASTYPE_SHOW,
  SEARCH_ASTYPE_HIDE,
  SEARCH_FULLTEXT_LOADING,
  SEARCH_FULLTEXT_RESULT,
  SEARCH_GET_P21_QTY,
  SEARCH_GET_P21_PRICEAVAIL,
  SEARCH_LOADING_P21_PRICEAVAIL,
  SEARCH_LOADING_PRODDETAILS,
  SEARCH_GET_PRODDETAILS,
} from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload = {} } = action;
  const { keyName = '', p21Info = {} } = payload;

  switch (type) {
    case SEARCH_LOADING_PRODDETAILS:
      return {
        ...state,
        [keyName]: {
          loading: true,
        },
      };
    case SEARCH_GET_PRODDETAILS:
      return {
        ...state,
        [keyName]: {
          ...payload,
          loading: false,
        },
      };
    case SEARCH_LOADING_P21_PRICEAVAIL:
    case SEARCH_GET_P21_PRICEAVAIL:
      if (payload !== undefined && p21Info !== undefined) {
        //merge the state into our new key value pairs
        for (var key of Object.keys(p21Info)) {
          //see if our state object has this same key
          if (
            state[keyName] !== undefined &&
            state[keyName].p21Info !== undefined &&
            state[keyName].p21Info.hasOwnProperty(key)
          ) {
            p21Info[key] = {
              ...state[keyName].p21Info[key],
              ...p21Info[key],
            };
          }
        }
      }

      if (
        state[keyName] !== undefined &&
        state[keyName].p21Info !== undefined
      ) {
        return {
          ...state,
          [keyName]: {
            p21Info: {
              ...state[keyName].p21Info,
              ...p21Info,
            },
          },
        };
      }
      if (
        state[keyName] === undefined ||
        state[keyName].p21Info === undefined
      ) {
        return {
          ...state,
          [keyName]: {
            p21Info: {
              ...p21Info,
            },
          },
        };
      }
      return state;
    case SEARCH_GET_P21_QTY:
      return {
        ...state,
        [keyName]: {
          p21Info: p21Info,
        },
      };
    case SEARCH_FULLTEXT_RESULT:
      return {
        ...state,
        [keyName]: {
          loading: false,
          searchResultsArr: payload.searchResultsArr,
          searchTerm: payload.searchTerm,
          searchPartNumber: payload.searchPartNumber,
          pag: payload.pag,
          limit: payload.limit,
          productCountTotal: payload.productCountTotal,
          searchAnalyticsID: payload.searchAnalyticsID,
        },
      };
    case SEARCH_FULLTEXT_LOADING:
      return {
        ...state,
        [keyName]: {
          loading: true,
          searchResultsArr: [],
          searchTerm: payload.searchTerm,
          searchPartNumber: payload.searchPartNumber,
          pag: 1,
          limit: 1,
          productCountTotal: 0,
        },
      };
    case SEARCH_ASTYPE_RESULT:
      return {
        ...state,
        [keyName]: {
          asYouTypeArray: payload.asYouTypeArray,
          showAsYouType: true,
          loading: false,
        },
      };
    case SEARCH_ASTYPE_CLEAR:
      return {
        ...state,
        [keyName]: {
          asYouTypeArray: [],
          showAsYouType: false,
          loading: false,
        },
      };
    case SEARCH_ASTYPE_SHOW:
      return {
        ...state,
        [keyName]: {
          showAsYouType: true,
        },
      };
    case SEARCH_ASTYPE_HIDE:
      return {
        ...state,
        [keyName]: {
          showAsYouType: false,
        },
      };
    default:
      return state;
  }
}
