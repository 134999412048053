import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from '../../../actions/auth';

import './Header.css';
import headerLogo from './headerLogo.gif';
import headerIdealSupplier from './headerIdealSupplier.png';
import NavDrawerBtn from '../navDrawerBtn/NavDrawerBtn';

const Header = ({
  drawerClick,
  logout,
  auth: { isAuthenticated, user = {} },
}) => {
  let name = '';
  if (user != null && user.name != null) {
    name = user.name;
  }
  //const { isAuthenticated = false, user = {} } = auth;
  //const { name = '' } = user;

  return (
    <div className='header-outer-spacer'>
      <div className='header-outer'>
        <header>
          <div className='centerContainer'>
            <div className='logo-outer'>
              <Link to='/'>
                <img src={headerLogo} alt='Stellar Industrial Supply' />
              </Link>{' '}
            </div>
            <div className='rightHamburgerDiv'>
              <div className='navDrawerBtnDiv' onClick={drawerClick}>
                <NavDrawerBtn />
              </div>
            </div>
            <div className='rightDiv'></div>
          </div>
        </header>
        <div className='barUnderHeader centerContainer'>
          <div className='leftDiv'>
            {isAuthenticated ? (
              <Fragment>
                <span>Welcome {name},</span>
                <span className='logout' onClick={(e) => logout()}>
                  Logout
                </span>
              </Fragment>
            ) : (
              <Link to='/login'>Employee Login</Link>
            )}
          </div>
          <div className='rightDiv'>
            <img
              src={headerIdealSupplier}
              alt='Ideal Supplier Partner Relationship'
            />
            <div>
              <a
                href='http://www.stellarindustrial.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                Visit StellarIndustrial.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  drawerClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Header);
