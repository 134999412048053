import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Spinner from '../../../layout/Spinner';
import PageHeading from '../../../layout/PageHeading/PageHeading';
import BottomShadow from '../../../layout/BottomShadow/BottomShadow';
import './ResetPassword.css';

import {
  checkResetCodeValid,
  resetPasswordWithCode,
} from '../../../../actions/auth';

const ResetPassword = ({
  match,
  checkResetCodeValid,
  resetPasswordWithCode,
  auth,
  isAuthenticated,
}) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    passwordErrorMsg: '',
  });
  const { email = '', password = '', passwordErrorMsg = '' } = formData;
  const { resetCode = '' } = match.params;

  //set that code is VALID, our code will check if it is further down
  const {
    resetCodeNotValid = false,
    resetCodeChecked = false,
    resetCodeLoading = true,
    loading = true,
  } = auth;

  useEffect(() => {
    if (!loading && !isAuthenticated && !resetCodeChecked) {
      checkResetCodeValid(resetCode);
    }
  }, [
    resetCodeChecked,
    isAuthenticated,
    loading,
    checkResetCodeValid,
    resetCode,
  ]);

  //safety in case a user ends up here without a reset code
  if (resetCode === null || resetCode === '') {
    return <Redirect to='/login' />;
  }

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onBlur = (e) => {
    //verify password meets requirements before sending on
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );

    if (!strongRegex.test(e.target.value)) {
      setFormData({
        ...formData,
        passwordErrorMsg:
          'Password must have at least 1 uppercase letter, 1 number, 1 special character and be longer than 8 characters.',
      });
      return;
    }
    setFormData({ ...formData, passwordErrorMsg: '' });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    //verify password meets requirements before sending on
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );

    if (!strongRegex.test(password)) {
      setFormData({
        ...formData,
        passwordErrorMsg:
          'Password must have at least 1 uppercase letter, 1 number, 1 special character and be longer than 8 characters.',
      });
      return;
    }
    setFormData({ ...formData, passwordErrorMsg: '' });
    resetPasswordWithCode(resetCode, email, password);
  };

  //redirect if the code is invalid
  if (resetCodeChecked && resetCodeNotValid) {
    //kick our user out of here
    return <Redirect to='/login' />;
  }

  //Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to='/employee/dashboard' />;
  }

  return (
    <div className='resetPassPage'>
      <div className='centerContainer'>
        <PageHeading
          config={{
            preHeading: 'Login Credentials Recovery',
            heading: 'Set Your Password',
            description:
              'Enter your email address and new password below to set your password.',
            graphic: 'fas fa-key',
          }}
        />

        <div className='contentBox'>
          {resetCodeLoading || isAuthenticated ? (
            <Spinner />
          ) : (
            <form className='form' onSubmit={(e) => onSubmit(e)}>
              <div className='emailDiv'>
                Enter Your Email Address
                <br />
                <input
                  type='text'
                  name='email'
                  value={email}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className='passwordDiv'>
                Enter Your New Password
                <br />
                <input
                  type='password'
                  name='password'
                  minLength='6'
                  value={password}
                  onChange={(e) => onChange(e)}
                  onBlur={(e) => onBlur(e)}
                />
                {passwordErrorMsg !== '' && (
                  <div className='passwordError'>{passwordErrorMsg}</div>
                )}
              </div>
              <div className='actionBtns'>
                <input
                  type='submit'
                  id='forgotButton'
                  value='Reset Your Password'
                />
              </div>
            </form>
          )}
        </div>
        <BottomShadow />
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  checkResetCodeValid,
  resetPasswordWithCode,
})(ResetPassword);
