import axios from 'axios';
import {
  SEARCH_ASTYPE_RESULT,
  SEARCH_ASTYPE_CLEAR,
  SEARCH_ASTYPE_SHOW,
  SEARCH_ASTYPE_HIDE,
  SEARCH_FULLTEXT_LOADING,
  SEARCH_FULLTEXT_RESULT,
  SEARCH_GET_P21_QTY,
  SEARCH_GET_P21_PRICEAVAIL,
  SEARCH_LOADING_P21_PRICEAVAIL,
  SEARCH_LOADING_PRODDETAILS,
  SEARCH_GET_PRODDETAILS,
} from './types';

export const getProductDetailsForPopup = (keyName, _id) => async (dispatch) => {
  let payload = {
    keyName,
  };

  //update the UI so it knows we are working on it
  dispatch({ type: SEARCH_LOADING_PRODDETAILS, payload: payload });
  const res = await axios.get(`/api/search/prodDetails/${_id}`);

  //check what just came back, set defaults if null
  payload = { ...res.data, keyName };

  dispatch({ type: SEARCH_GET_PRODDETAILS, payload: payload });

  try {
  } catch (error) {}
};

export const recordItemClickAnalytics = (_id, itemID) => async (dispatch) => {
  //this is a blind request sent only to record our analytics data
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = {
    _id,
    itemID,
  };

  // eslint-disable-next-line
  const res = await axios.post('/api/search/analytics/itemClick', body, config);
};

export const recordSearchAnalyticFeedback = (_id, matchFound = true) => async (
  dispatch
) => {
  //this is a blind request to tell analytics if the user found their item
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = {
    _id,
    matchFound,
  };

  // eslint-disable-next-line
  const res = await axios.post(
    '/api/search/analytics/matchFound',
    body,
    config
  );
};

export const getP21PriceAvail = (
  keyName,
  itemID,
  inv_mast_uid,
  customerID = 127279,
  shipToID = 127279,
  salesLocID = 1
) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = {
    customerID,
    shipToID,
    salesLocID,
    products: [itemID],
  };

  let payload = {
    keyName,
    p21Info: {
      [inv_mast_uid]: {
        pricingLoading: true,
        pricingDisplay: true,
      },
    },
  };

  //update the UI so it knows we are working on it
  dispatch({ type: SEARCH_LOADING_P21_PRICEAVAIL, payload: payload });

  const res = await axios.post(`/api/search/priceAvailability`, body, config);

  let tempPayload = res.data;

  //set loading to false for each object we just got
  for (let key of Object.keys(tempPayload)) {
    tempPayload[key].pricingLoading = false;
  }

  //rewrite out payload so we can update the UI
  payload = {
    keyName,
    p21Info: tempPayload,
  };

  dispatch({ type: SEARCH_GET_P21_PRICEAVAIL, payload: payload });
  try {
  } catch (err) {}
};

export const searchQtyOnHand = (keyName = '', searchResultsArr = []) => async (
  dispatch
) => {
  try {
    if (
      searchResultsArr === null ||
      searchResultsArr === undefined ||
      searchResultsArr.length === 0
    ) {
      //reset our qtyOnHandData
      return;
    }

    const productsToLookup = [];

    //break apart our results array into just the inv_mast_uids
    searchResultsArr.forEach((searchResult) => {
      productsToLookup.push(searchResult.inv_mast_uid);
    });

    //push our array of itemIDs to the middleware server
    let res;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = {
      products: productsToLookup,
    };

    res = await axios.post(`/api/search/qtyOnHand`, body, config);
    let payload = {
      keyName,
      p21Info: res.data,
    };

    dispatch({ type: SEARCH_GET_P21_QTY, payload: payload });
  } catch (error) {}
};

export const searchFullText = (
  keyName,
  formData = {},
  _searchAnalyticsID = 0,
  newAnalyticsRecord = false,
  pag = 1
) => async (dispatch) => {
  //get our options to send along with this payload
  let {
    adStarsSearchFilter = 'starsandad_preferred',
    searchLoose = 'true',
    stockableFilter = 'false',
    searchBarPartNumber = '',
    searchBar = '',
    limit = 30,
  } = formData;

  //remove any pound signs before we send the search
  searchBar = searchBar.replace('#', 'NO');

  //console.log(formData);

  let payload = {
    keyName,
    searchTerm: searchBar,
    searchPartNumber: searchBarPartNumber,
    searchResultsArr: [],
    adStarsSearchFilter: adStarsSearchFilter,
    pag,
    limit,
    productCountTotal: 0,
    newAnalyticsRecord,
  };

  //flag that we are loading, this is for the UI
  await dispatch({ type: SEARCH_FULLTEXT_LOADING, payload });

  if (searchBar.length >= 2 || searchBarPartNumber.length >= 2) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = {
      searchTerm: searchBar,
      searchPartNumber: payload.searchPartNumber,
      adStarsSearchFilter: payload.adStarsSearchFilter,
      searchLoose,
      stockableFilter,
      pag: payload.pag,
      limit: payload.limit,
      newAnalyticsRecord,
    };

    const res = await axios.post(`/api/search/fullText/`, body, config);

    //check what just came back, set defaults if null
    const {
      searchTerm,
      searchResultsArr = [],
      adStarsSearchFilter = 'starsandad_preferred',
      pag = 1,
      limit = 15,
      productCountTotal = 0,
      searchAnalyticsID = 0,
    } = res.data;

    //throw into the payload
    payload = {
      ...payload,
      searchTerm,
      searchResultsArr,
      adStarsSearchFilter,
      pag,
      limit,
      productCountTotal,
      searchAnalyticsID,
    };

    //if we aren't getting new analytics then the res data will not have a valid analytics ID anymore
    if (!newAnalyticsRecord) {
      payload.searchAnalyticsID = _searchAnalyticsID;
    }
  }
  dispatch({ type: SEARCH_FULLTEXT_RESULT, payload });
  try {
  } catch (err) {
    dispatch({ type: SEARCH_FULLTEXT_RESULT, payload });
  }
};

export const showAsType = (keyName) => async (dispatch) => {
  let payload = {
    keyName,
  };
  dispatch({ type: SEARCH_ASTYPE_SHOW, payload });
};

export const hideAsType = (keyName) => async (dispatch) => {
  let payload = {
    keyName,
  };
  dispatch({ type: SEARCH_ASTYPE_HIDE, payload });
};

export const searchAsType = (
  keyName = 'emp_prodfuzzymatch',
  searchTerm = '',
  fieldName = ''
) => async (dispatch) => {
  let payload = {
    keyName,
    asYouTypeArray: [],
  };
  try {
    let res;

    if (searchTerm.length >= 2 && searchTerm.length < 25) {
      let searchTermModified = searchTerm.replace('#', 'NO');
      res = await axios.get(`/api/search/asType/${searchTermModified}`);

      //check what just came back, set a default if null, store in the payload
      const { asYouTypeArray = [] } = res.data;
      payload.asYouTypeArray = asYouTypeArray;
      dispatch({ type: SEARCH_ASTYPE_RESULT, payload });
    } else {
      dispatch({ type: SEARCH_ASTYPE_CLEAR, payload });
    }
  } catch (err) {
    dispatch({ type: SEARCH_ASTYPE_CLEAR, payload });
  }
};

export const clearSearchAsType = (keyName) => async (dispatch) => {
  let payload = {
    keyName,
  };
  dispatch({
    type: SEARCH_ASTYPE_CLEAR,
    payload,
  });
};
