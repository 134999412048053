import {
  SFSYNC_LOADING,
  SFSYNC_RESULT,
  SFSYNC_SAVED,
  SFSYNC_CLEARSAVED,
  SFSYNC_ERROR,
  SFSYNC_LOGRESULT,
  SFSYNC_LOGERROR,
} from "../actions/types";

const initialState = {
  loading: true,
  saveSuccess: false,
  settings: {},
  logs: {},
  errorMsg: "",
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SFSYNC_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case SFSYNC_RESULT:
      return {
        ...state,
        loading: false,
        settings: payload,
      };
    case SFSYNC_SAVED:
      return {
        ...state,
        loading: false,
        settings: payload,
        saveSuccess: true,
      };
    case SFSYNC_CLEARSAVED:
      return {
        ...state,
        saveSuccess: false,
      };
    case SFSYNC_ERROR: {
      return {
        ...state,
        saveSuccess: false,
        errorMsg: payload,
      };
    }
    case SFSYNC_LOGRESULT: {
      return {
        ...state,
        logs: payload,
      };
    }
    case SFSYNC_LOGERROR: {
      return {
        ...state,
        logs: {},
      };
    }
    default:
      return state;
  }
}
