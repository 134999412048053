import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const AdminRoute = ({ component: Component, auth, ...rest }) => {
  const { isAuthenticated = false, loading = true, user = {} } = auth;
  const { permissions = {} } = user;
  const { admin = false } = permissions;

  let toLoginScreen = false;

  if (!loading && !isAuthenticated) toLoginScreen = true;
  if (!loading && !admin) toLoginScreen = true;

  return (
    <Route
      {...rest}
      render={(props) =>
        toLoginScreen ? (
          <Fragment>
            <Redirect to="/login" />
          </Fragment>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

AdminRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AdminRoute);
