import { combineReducers } from "redux";
import expedite from "./expedite";
import search from "./search";
import searchCompany from "./searchCompany";
import searchShipTo from "./searchShipTo";
import bulkFuzzyMatch from "./bulkFuzzyMatch";
import solutions from "./solutions";
import solutionsDashboard from "./solutionsDashboard";
import solutionsPreflight from "./solutionsPreFlight";
import auth from "./auth";
import userSettings from "./userSettings";
import appDataSFSync from "./appData_sfSync";
import alert from "./alert";
import allUsers from "./allUsers";

export default combineReducers({
  expedite,
  search,
  searchCompany,
  searchShipTo,
  bulkFuzzyMatch,
  solutions,
  solutionsDashboard,
  solutionsPreflight,
  auth,
  alert,
  userSettings,
  appDataSFSync,
  allUsers,
});
