import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { formatMoney } from '../../../utils/currencyFormat';

import './ProdFuzzyMatch_singleOverlay.css';
import ProductImgArea from './ProductImgArea';
import miniLoading from './miniLoading.svg';

const ProdFuzzyMatch_singleOverlay_sec1 = ({
  search_state = {},
  prodTitle = '',
  searchFeedbackOnClick,
  searchAnalytics = {},
}) => {
  const {
    emp_prodfuzzy_fulltext_p21Info = {},
    emp_prodfuzzy_fulltext_productdetails = {},
  } = search_state;

  const {
    p21Data = {},
    ADData = {},
    manufacturerData = {},
  } = emp_prodfuzzy_fulltext_productdetails;

  const { item_id = '', inv_mast_uid = '' } = p21Data;
  const { productImages = [] } = ADData;
  const { manu_part_number = '' } = manufacturerData;

  const {
    pricingLoading = false,
    unitPrice = 0,
    priceUOM = 'EA',
    priceUnitSize = 1,
    priceError = false,
    priceErrorMsg = '',
  } = assignVars(inv_mast_uid, emp_prodfuzzy_fulltext_p21Info);
  const { analyticsSubmitted = false } = searchAnalytics;

  /*
<div className='actionLinks'>
        <ul>
          <li>Email To Customer</li>
          <li>Email To Product Management</li>
          <li>Print</li>
        </ul>
      </div>
  */
  return (
    <div className='summary_sec'>
      <div className='summary_sec-left'>
        <ProductImgArea assets={productImages} />
        <div className='summary_info'>
          <div className='topInfo'>
            <div className='title'>{prodTitle.toUpperCase()}</div>
            <div className='subTitle'>
              Mfg Part Number:{' '}
              {manu_part_number !== '' ? manu_part_number : ' -'}
            </div>
          </div>
          <div className='bottomInfo'>
            <div>
              <b>P21 Item:</b> {item_id !== '' ? item_id : '-'}
            </div>
            <div>
              <b>Price:</b>{' '}
              {inv_mast_uid === '' && (
                <span style={{ opacity: '.5' }}>
                  <i>Not Priced in P21</i>
                </span>
              )}
              {inv_mast_uid !== '' && pricingLoading && (
                <img src={miniLoading} alt='' style={{ opacity: '.2' }} />
              )}
              {inv_mast_uid !== '' && !pricingLoading && !priceError && (
                <Fragment>
                  ${formatMoney(unitPrice)} {priceUOM} Unit Size:{' '}
                  {priceUnitSize}
                </Fragment>
              )}
              {inv_mast_uid !== '' && !pricingLoading && priceError && (
                <Fragment>{priceErrorMsg}</Fragment>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className='secFeedback'>
            {!analyticsSubmitted && (
              <Fragment>
                Did you find a good match?
                <div className='secFeedback-float'>
                  <i
                    className='far fa-thumbs-up'
                    onClick={(e) => searchFeedbackOnClick(true)}
                  />
                  <i
                    className='far fa-thumbs-down'
                    onClick={(e) => searchFeedbackOnClick(false)}
                  />
                </div>
              </Fragment>
            )}
            {analyticsSubmitted && (
              <Fragment>Thank you for your feedback...</Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const assignVars = (inv_mast_uid = '', emp_prodfuzzy_fulltext_p21Info = {}) => {
  if (
    inv_mast_uid !== '' &&
    emp_prodfuzzy_fulltext_p21Info !== null &&
    emp_prodfuzzy_fulltext_p21Info.p21Info !== undefined &&
    emp_prodfuzzy_fulltext_p21Info.p21Info !== null &&
    emp_prodfuzzy_fulltext_p21Info.p21Info[inv_mast_uid] !== undefined &&
    emp_prodfuzzy_fulltext_p21Info.p21Info[inv_mast_uid] !== null
  ) {
    return emp_prodfuzzy_fulltext_p21Info.p21Info[inv_mast_uid];
  }
  return {};
};

ProdFuzzyMatch_singleOverlay_sec1.propTypes = {
  search_state: PropTypes.object.isRequired,
  prodTitle: PropTypes.string.isRequired,
};

export default ProdFuzzyMatch_singleOverlay_sec1;
