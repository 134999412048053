import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {
  loadAllUsers,
  resetUserPassword,
  togglePermission,
} from "../../../../actions/allUsers";

import PageHeading from "../../../layout/PageHeading/PageHeading";
import BottomShadow from "../../../layout/BottomShadow/BottomShadow";
import Spinner from "../../../layout/Spinner";
import User from "./User";

import "./AllUsers.css";
import ResetPassword from "./ResetPassword";

const AllUsers = ({
  loadAllUsers,
  allUsersState,
  resetUserPassword,
  togglePermission,
}) => {
  const [allUsers, setAllUsers] = useState({
    loading: false,
    error: false,
    users: [],
    resetPasswordModalOpen: true,
    resetPassword: "",
    resetPassword_id: null,
  });

  //get our first load for all the users
  useEffect(() => {
    loadAllUsers();
  }, [loadAllUsers]);

  //put data in local mutable state object
  useEffect(() => {
    setAllUsers(allUsersState);
  }, [allUsersState]);

  //accessed at the user component level
  const toggleUserDetail = (_id = "", allUsers) => {
    //toggle the visible var in our state object
    const indexLoc = allUsers.users.findIndex((x) => x._id === _id);

    //guard against not finding the user ID
    if (indexLoc < 0) return;

    //pull into our state object because we can't do this direct in the setAllUsers object
    let tempState = allUsers;
    tempState.users[indexLoc].detailsVisible =
      !tempState.users[indexLoc].detailsVisible;

    setAllUsers({ ...tempState });
  };

  const onClickResetPassword = (_id) => {
    setAllUsers({
      ...allUsers,
      resetPasswordModalOpen: true,
      resetPassword: "",
      resetPassword_id: _id,
    });
  };

  const onPasswordFieldChange = (e) => {
    setAllUsers({
      ...allUsers,
      resetPassword: e.target.value,
    });
  };

  const execPasswordReset = () => {
    const { resetPassword_id = "", resetPassword = "" } = allUsers;
    resetUserPassword(resetPassword_id, resetPassword);
    setAllUsers({
      ...allUsers,
      resetPasswordModalOpen: false,
      resetPassword: "",
      resetPassword_id: null,
    });
  };

  const closePasswordReset = () => {
    setAllUsers({
      ...allUsers,
      resetPasswordModalOpen: false,
      resetPassword: "",
      resetPassword_id: null,
    });
  };

  const onClickPermission = (_id, permission, reqState) => {
    console.log("clicked");
    togglePermission(_id, permission, reqState);
  };

  //break out the variables we need for the top UI
  const { loading = true, error = false, users = [] } = allUsers;

  return (
    <div id="admin_allUsers">
      {allUsers.resetPasswordModalOpen && (
        <ResetPassword
          onPasswordFieldChange={onPasswordFieldChange}
          execPasswordReset={execPasswordReset}
          closePasswordReset={closePasswordReset}
          allUsers={allUsers}
        />
      )}
      <div className="centerContainer">
        <PageHeading
          config={{
            preHeading: "Restricted Access Area",
            heading: "Manage All Users",
            description: "",
            graphic: "fas fa-user-shield",
          }}
        />
        <div className="contentBox">
          Select a user below to view details and manage the user.
          <div className="users-outer">
            {loading && <Spinner />}
            {!loading &&
              users.sort().map((user) => {
                const { _id = "" } = user;
                return (
                  <User
                    key={_id}
                    user={user}
                    allUsers={allUsers}
                    toggleUserDetail={toggleUserDetail}
                    onClickResetPassword={onClickResetPassword}
                    onClickPermission={onClickPermission}
                  />
                );
              })}
          </div>
        </div>
        <BottomShadow />
      </div>
    </div>
  );
};

AllUsers.propTypes = {};

const mapStateToProps = (state) => ({
  allUsersState: state.allUsers,
});

export default connect(mapStateToProps, {
  loadAllUsers,
  resetUserPassword,
  togglePermission,
})(AllUsers);
