import {
  EXPEDITE_LOADING,
  EXPEDITE_RECEIVED,
  EXPEDITE_ERROR,
  EXPEDITE_CLEAR,
  EXPEDITE_LOADORDER,
  EXPEDITE_RECEIVEDORDER,
  EXPEDITE_VIEW_LINEITEMS,
  EXPEDITE_VIEW_TRACKING,
} from '../actions/types';

const initialState = {
  loading: false,
  searchedFor: '',
  data: [],
  detailedOrderInfo: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case EXPEDITE_LOADING:
      return {
        ...state,
        loading: true,
        searchedFor: payload,
      };
    case EXPEDITE_RECEIVED:
      return {
        ...state,
        loading: false,
        data: payload.data,
      };
    case EXPEDITE_LOADORDER:
      return {
        ...state,
        detailedOrderInfo: {
          ...state.detailedOrderInfo,
          [payload.order_no]: payload.data,
        },
      };
    case EXPEDITE_RECEIVEDORDER:
      return {
        ...state,
        detailedOrderInfo: {
          ...state.detailedOrderInfo,
          [payload.order_no]: payload.data,
        },
      };

    case EXPEDITE_VIEW_LINEITEMS:
      return {
        ...state,
        detailedOrderInfo: {
          ...state.detailedOrderInfo,
          [payload.order_no]: {
            ...state.detailedOrderInfo[payload.order_no],
            viewUI: 'lineItems',
          },
        },
      };
    case EXPEDITE_VIEW_TRACKING:
      return {
        ...state,
        detailedOrderInfo: {
          ...state.detailedOrderInfo,
          [payload.order_no]: {
            ...state.detailedOrderInfo[payload.order_no],
            viewUI: 'tracking',
          },
        },
      };
    case EXPEDITE_CLEAR:
      return {
        ...state,
        loading: false,
        searchedFor: '',
        data: [],
        detailedOrderInfo: {},
      };
    case EXPEDITE_ERROR: {
      return {
        ...state,
        loading: false,
        data: [],
        detailedOrderInfo: {},
      };
    }
    default:
      return state;
  }
}
