import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { logout } from "../../../actions/auth";

/* Import Images */
import headerLogo from "./headerLogo.png";
import Fundamental from "../Fundamental/Fundamental";

/* Import CSS */
import "./SideDrawer.css";

const SideDrawer = ({
  logout,
  click,
  show,
  auth: { isAuthenticated, user, loading },
}) => {
  const { permissions = {} } = user;
  const { admin = false, solutions = false } = permissions;

  let drawerClasses = "side-drawer";
  if (show) {
    drawerClasses = "side-drawer open";
  }

  const onLogoutClick = () => {
    click(); //close the sidebar
    logout();
  };

  return loading ? (
    <Fragment></Fragment>
  ) : (
    <nav className={drawerClasses}>
      <div className="topBlueDiv">
        <Link to="/" onClick={click}>
          <img src={headerLogo} id="sideDrawer_logo" alt="" />
        </Link>
      </div>
      <div className="links-outer">
        <ul>
          {isAuthenticated && (
            <li>
              <Link to="/employee/dashboard" onClick={click}>
                <div className="icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Your Dashboard
              </Link>
            </li>
          )}
          <li>
            <Link to="/expedite" onClick={click}>
              <div className="icon">
                <i className="fas fa-truck"></i>
              </div>
              Order Tracking
            </Link>
          </li>
          {isAuthenticated && (
            <li>
              <Link to="/employee/prodfuzzymatch" onClick={click}>
                <div className="icon">
                  <i className="fas fa-list-alt"></i>
                </div>
                Product Identification Helper
              </Link>
            </li>
          )}

          {isAuthenticated && solutions && (
            <li>
              <Link to="/employee/solutions/dashboard">
                <div className="icon">
                  <i className="fas fa-draw-polygon fa-fw"></i>
                </div>
                Solutions Dashboard
              </Link>
            </li>
          )}
          {isAuthenticated && (
            <li>
              <Link to="/admin/dashboard">
                <div className="icon">
                  <i className="fas fa-user-shield fa-fw"></i>
                </div>
                Admin Dashboard
              </Link>
            </li>
          )}
          {isAuthenticated && admin && (
            <li>
              <Link to="/employee/accountsettings">
                <div className="icon">
                  <i className="fas fa-user-cog fa-fw"></i>
                </div>
                Profile
              </Link>
            </li>
          )}
          <li onClick={(e) => onLogoutClick()} className="logout">
            {isAuthenticated ? (
              <Fragment>
                <div className="icon">
                  <i className="fas fa-sign-out-alt fa-fw"></i>
                </div>
                Logout
              </Fragment>
            ) : (
              <Link to="/login" onClick={click}>
                <div className="icon">
                  <i className="fas fa-sign-in-alt fa-fw"></i>
                </div>
                Login
              </Link>
            )}
          </li>
        </ul>
      </div>
      <div className="fundamentalBottom">
        <Fundamental />
      </div>
    </nav>
  );
};
/*
       
          {isAuthenticated && (
            <li>
              <Link to='/employee/accountsettings'>
                <div className='icon'>
                  <i className='fas fa-user-cog fa-fw'></i>
                </div>
                Profile
              </Link>
            </li>
          )}   
*/

/*
{isAuthenticated && (
            <li>
              <Link to='/employee/jobcontract' onClick={click}>
                <div className='icon'>
                  <i className='fas fa-file-contract'></i>
                </div>
                Job Contract Analyzer
              </Link>
            </li>
          )}
          */

SideDrawer.propTypes = {
  click: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logout })(SideDrawer);
