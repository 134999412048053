import axios from 'axios';
import {
  BULKFUZZY_FORM_LOADING,
  BULKFUZZY_FORM_ERROR,
  BULKFUZZY_FORM_SUCCESS,
  BULKFUZZY_FORM_RESET,
  BULKFUZZY_TABLE_LOADING,
  BULKFUZZY_TABLE_RESULT,
} from './types';

export const temp_p21SFInit = () => async (dispatch) => {
  axios.get(`/adminAPI/p21_sf_Sync/initialize`);
};
export const temp_p21SFDelta = () => async (dispatch) => {
  axios.get(`/adminAPI/p21_sf_Sync/delta`);
};
export const pullFromMiddleware = () => async (dispatch) => {
  axios.get(`/adminAPI/p21/getProductData/getAllToMongo`);
};
export const pushProductsIntoElastic = () => async (dispatch) => {
  axios.get(`/adminAPI/elastic/products/index/add/products`);
};

export const errorBulkFuzzy = (error = 'Please complete form') => async (
  dispatch
) => {
  dispatch({ type: BULKFUZZY_FORM_ERROR, payload: error });
};

export const submitBulkFuzzy = (formData) => async (dispatch) => {
  try {
    dispatch({ type: BULKFUZZY_FORM_LOADING });

    let res;
    const config = {
      headers: {
        'Content-Type': `multipart/form-data; ${formData._boundary}`,
      },
    };

    res = await axios.post(`/api/bulkProdFuzzyMatch/`, formData, config);

    setTimeout(() => {
      dispatch({ type: BULKFUZZY_FORM_SUCCESS, payload: res.data._id });
    }, 3000);

    setTimeout(() => {
      dispatch({ type: BULKFUZZY_FORM_RESET });
    }, 60000);
  } catch (err) {
    console.log(err.response);
    if (err && err.response && err.response.status && err.response.data.msg) {
      dispatch({
        type: BULKFUZZY_FORM_ERROR,
        payload: err.response.data.msg,
      });
    }
  }
};

export const resetBulkFuzzy = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: BULKFUZZY_FORM_RESET,
    });
  } catch (err) {}
};

export const getBulkFuzzyProjects = (initialLoad = true) => async (
  dispatch
) => {
  try {
    if (initialLoad) {
      dispatch({ type: BULKFUZZY_TABLE_LOADING });
    }

    const res = await axios.get(`/api/bulkProdFuzzyMatch/all`);

    dispatch({ type: BULKFUZZY_TABLE_RESULT, payload: res.data });
  } catch (err) {
    console.log(err.response);
    if (err && err.response && err.response.status && err.response.data.msg) {
    }
  }
};

export const deleteBulkFuzzyProject = (id) => async (dispatch) => {
  try {
    dispatch({ type: BULKFUZZY_TABLE_LOADING });

    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };

    //delete the post, we get the table of all projects back
    const res = await axios.post(
      `/api/bulkProdFuzzyMatch/delete/${id}`,
      {},
      config
    );

    dispatch({ type: BULKFUZZY_TABLE_RESULT, payload: res.data });
  } catch (err) {
    console.log(err.response);
    if (err && err.response && err.response.status && err.response.data.msg) {
    }
  }
};
