import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

const User = ({
  user = {},
  allUsers = {},
  toggleUserDetail,
  onClickResetPassword,
  onClickPermission,
}) => {
  const {
    _id = "",
    firstName = "",
    lastName = "",
    permissions = {},
    delete_flag = false,
    email = "",
    p21_users_uid = 0,
    branch_id,
    registerDate = null,
    lastLoginDate = null,
    detailsVisible = false,
  } = user;
  const { admin = false, solutions = false } = permissions;

  // const onResetPasswordClick = (_id, newPassword) => {
  //   //reset the password field
  //   // setNewPassword("");

  //   const resetPassword = (_id) => {
  //     resetUserPassword(_id, newPassword);
  //     setNewPassword("");
  //   };

  //   const onPasswordFieldChange = (e) => {
  //     console.log(e);
  //     setNewPassword("foobar");
  //   };

  //   //do a popup to set this
  //   confirmAlert({
  //     customUI: ({ onClose }) => {
  //       console.log(newPassword);
  //       return (
  //         <div className="supplierPO overlay-acknowledge">
  //           <div className="title">Reset Users Password</div>
  //           <div className="instructions"></div>
  //           <div className="text">
  //             New Password: {newPassword}{" "}
  //             <input
  //               type="text"
  //               onChange={(e) => onPasswordFieldChange(e)}
  //               value={"damn"}
  //             />
  //           </div>

  //           <button
  //             className="saveBtn"
  //             onClick={() => {
  //               resetPassword(_id);
  //               onClose();
  //             }}
  //           >
  //             Reset User Password
  //           </button>
  //           <button onClick={onClose}>Cancel</button>
  //         </div>
  //       );
  //     },
  //   });
  // };

  return (
    <div className="user-outer">
      <div className="top" onClick={(e) => toggleUserDetail(_id, allUsers)}>
        <div>
          {firstName} {lastName}
        </div>
        {delete_flag ? (
          <div className="red">P21 Deleted</div>
        ) : (
          <div className="green">Active</div>
        )}
      </div>
      {detailsVisible && (
        <Fragment>
          <div className="user-detail">
            <div>
              {email}
              <br />
              P21 User UID: {p21_users_uid}
              <br />
              Branch ID: {branch_id}
            </div>
            <div>
              Registered:{" "}
              {moment(registerDate).isValid()
                ? moment(registerDate).format("MM/DD/YY")
                : ""}
              <br />
              Last Login:{" "}
              {moment(lastLoginDate).isValid()
                ? moment(lastLoginDate).format("MM/DD/YY")
                : ""}
              <br />
              <span
                className="clickable"
                onClick={() => onClickResetPassword(_id)}
                data-id={_id}
                data-usernames={`${firstName} ${lastName}`}
              >
                Reset Password
              </span>
            </div>
          </div>
          <div className="permissions-outer">
            <b>Permissions:</b>
            <div className="permissions">
              <div>
                <span
                  className="clickable"
                  onClick={() => onClickPermission(_id, "admin", !admin)}
                >
                  Admin: {admin ? "Yes" : "No"}
                </span>
              </div>
              <div>
                <span
                  className="clickable"
                  onClick={() =>
                    onClickPermission(_id, "solutions", !solutions)
                  }
                >
                  Solutions: {solutions ? "Yes" : "No"}
                </span>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

User.propTypes = {
  user: PropTypes.object.isRequired,
  allUsers: PropTypes.object.isRequired,
  toggleUserDetail: PropTypes.func.isRequired,
};

export default User;
