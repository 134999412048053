import React, { useState, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import PageHeading from '../../../layout/PageHeading/PageHeading';
import BottomShadow from '../../../layout/BottomShadow/BottomShadow';

import { emailForgotPassword } from '../../../../actions/auth';
import './ForgotPassword.css';

const ForgotPassword = ({ emailForgotPassword, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: '',
    success: false,
    errorMsg: '',
  });

  const { email, success, errorMsg } = formData;
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (email === '') {
      setFormData({
        ...formData,
        errorMsg: 'Please enter an email to reset your password',
      });
      return;
    }

    emailForgotPassword(email);
    setFormData({
      ...formData,
      errorMsg: '',
      success: true,
    });
  };

  //Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to='/employee/dashboard' />;
  }

  return (
    <div className='forgotPassPage'>
      <div className='centerContainer'>
        <PageHeading
          config={{
            preHeading: 'Login Credentials',
            heading: 'Reset Your Password',
            description:
              'Enter your email below to begin the reset password process.',
            graphic: 'fas fa-key',
          }}
        />

        <div className='contentBox'>
          {!success && (
            <Fragment>
              <form className='form' onSubmit={(e) => onSubmit(e)}>
                <div className='emailDiv'>
                  Email Address
                  <br />
                  <input
                    type='text'
                    name='email'
                    value={email}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                {errorMsg !== '' && <div className='error'>{errorMsg}</div>}
                <div className='actionBtns'>
                  <input
                    type='submit'
                    id='forgotButton'
                    value='Email Password Reset'
                  />
                </div>
              </form>
            </Fragment>
          )}
          {success && (
            <div style={{ textAlign: 'center' }}>
              An email with password reset instructions has been sent to:{' '}
              {email}. Please allow up to 10 mins for the email to be delivered.
            </div>
          )}

          <div className='returnLink'>
            <Link to='/login'>Return to Login Page</Link>
          </div>
        </div>

        <BottomShadow />
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { emailForgotPassword })(
  ForgotPassword
);
