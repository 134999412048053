import React from 'react';
import moment from 'moment';

import './Fundamental.css';

const Fundamental = (props) => {
  //Calculate the weekly fundamental to display, based on the date from the first of 2020
  //Apply an offset since the fundamental is off by 4 at Stellar.
  const a = moment(new Date('1/1/2020'), 'DD-MM-YYYY');
  const b = moment(new Date(), 'DD-MM-YYYY');
  const weeks = b.diff(a, 'week');
  let fundNumber = weeks - 4; //4 is the offset when we wrote this code
  while (fundNumber > 26) {
    fundNumber -= 26;
  }

  //get the fundamental to display
  const fundamentalObj = loadFundamentals(fundNumber);

  return (
    <div className='FundamentalComponent'>
      <div className='subTitle'>Stellar Fundamental Of The Week</div>
      <div className='title'>{fundamentalObj.title}</div>
      <div className='text'>{fundamentalObj.text}</div>
    </div>
  );
};

export default Fundamental;

const loadFundamentals = (fundNumber) => {
  const fund = {
    1: {
      title: 'Own It',
      text:
        'Take personal responsibility for making things happen. Respond to every situation by looking for how we can do it, rather than explaining why it can’t be done. Be resourceful, show initiative, and find a way! Don’t be deterred or make excuses or wait for others to solve the problem. See issues through to their completion. Play to win!',
    },
    2: {
      title: '"BRING IT" Every Day',
      text:
        'Have a passion for what we do and be fully engaged. Radiate a positive attitude – it’s contagious! Make the most of each day by approaching every task with energy, focus, purpose, and enthusiasm. Work with a sense of urgency to get things done. ',
    },
    3: {
      title: 'Listen Generously',
      text:
        'Listening is more than simply “not speaking.” Give others your undivided attention. Be present and engaged. Minimize the distractions and let go of the need to agree or disagree. Suspend your judgment and be curious to know more, rather than jumping to conclusions. Above all, listen to understand.',
    },
    4: {
      title: 'Speak Straight',
      text:
        'Speak honestly and with a giving spirit – in a way that helps to make progress. Be sincere, say what you mean, and be willing to ask questions, share ideas, or raise issues that may cause conflict when it’s necessary for team success. Mine for constructive conflict. Be courageous enough to say what needs to be said. Address issues directly with those who are involved or affected. Use face to face or phone communication to create clarity; emails do not promote effective two-way communication. ',
    },
    5: {
      title: 'Honor Commitments',
      text:
        'Do what you say you’re going to do, when you say you’re going to do it. This includes being on time for all phone calls, appointments, meetings, and promises. If a commitment can’t be fulfilled, notify others well in advance and agree on a new deliverable to be honored. Start and end meetings on time.',
    },
    6: {
      title: 'Be A Fanatic About Response Time',
      text:
        'Work with a sense of urgency to get things addressed and done. Respond to questions and concerns quickly, whether it’s in person, on the phone, or by email. This includes simply acknowledging that we got the question and we’re “on it,” as well as keeping those involved continuously updated on the status of outstanding issues.',
    },
    7: {
      title: 'Treat Everyone With Dignity And Respect',
      text:
        'Treat others in a way that honors their self-worth and respects their value as unique individuals, regardless of their background, appearance, or beliefs. Learn to walk in others’ shoes and to think from their perspective. Always act with kindness, compassion, and patience. ',
    },
    8: {
      title: 'Assume Positive Intent',
      text:
        'Work from the assumption that people are good, fair, and honest, and that the intent behind their actions is positive. Set aside your own judgments and preconceived notions. Give people the benefit of the doubt. ',
    },
    9: {
      title: 'Be Curious',
      text:
        'In the search for the best solutions, challenge and question what you don’t understand. Don’t accept anything at “face value” if it doesn’t make sense to you. Always ask why. Dig deep and get at the root cause of problems by asking thoughtful questions. Apply that learning to mitigate future risks.',
    },
    10: {
      title: 'Celebrate Success',
      text:
        'Recognizing people doing things right is more effective than pointing out when they do things wrong. Regularly extend meaningful acknowledgment and appreciation — in all directions throughout our organization.',
    },
    11: {
      title: 'Be A Servant Leader',
      text:
        'Non sibi (not for self). Don’t let your own ego or personal agenda get in the way of doing what’s best for the team. Be humble, put the needs of others first, and help others to succeed. Influence and lead through your own example. Take responsibility to teach, coach, guide, and mentor others. Be the change you want to see.',
    },
    12: {
      title: 'Do The Right Thing, Always',
      text:
        'Demonstrate an unwavering commitment to doing the right thing in every action you take and in every decision you make, especially when no one’s looking. Always tell the truth, no matter the consequences. If you make a mistake, own up to it, apologize, and make it right. Contrition matters and allows us to move forward.',
    },
    13: {
      title: 'Deliver Legendary Service',
      text:
        'It’s all about the experience. With every experience, do the little things, as well as the big things, that surprise people. Make every interaction stand out for its helpfulness. Create the “WOW” factor that turns customers into raving fans. This includes both internal and external customers.',
    },
    14: {
      title: 'Invest In Relationships',
      text:
        'Get to know your customers and co-workers on a more personal level. More conversations and less email. Understand what makes others tick and what’s important to them. Strong relationships enable us to more successfully work through difficult issues and challenging times.',
    },
    15: {
      title: 'Get Clear On Expectations',
      text:
        'Create clarity and avoid misunderstandings by discussing expectations up-front. Set expectations for others and ask when you’re not clear on what they expect of you. End all meetings with clarity about action items, responsibilities, and due dates.',
    },
    16: {
      title: 'Be Relentless About Improvement',
      text:
        'Be a lifelong learner. Seek out and take advantage of every opportunity to gain more knowledge, to increase your skills, and to become a greater expert. Regularly reevaluate every aspect of your work to find ways to improve. Don’t be satisfied with the status quo. “Because we’ve always done it that way” is not a reason. Make you and us – better.',
    },
    17: {
      title: 'Practice Blameless Problem Solving',
      text:
        'Focus on solutions rather than pointing fingers or dwelling on problems. Pointing out fires is not the same as putting out fires. Identify lessons learned and use those lessons to improve ourselves and our processes so we don’t make the same mistake twice. Get smarter with every mistake. Learn from every experience.',
    },
    18: {
      title: 'Be A Proud Stellar Ambassador',
      text:
        'We’re all responsible for, and benefit from, the Stellar image and reputation. Consider how your actions affect our collective reputation. Be responsible stewards for the benefit of our shareholders, associates, customers, suppliers, industry, and our communities. Make decisions that support our mission and in turn will deliver profit to both our customers and us.',
    },
    19: {
      title: 'Deliver Results',
      text:
        'While effort is important, our customers expect results. Focus on what’s important and do what works. Follow-up on everything and take responsibility to ensure that tasks get completed. Set high goals, use measurements to track your progress, and hold yourself accountable for achieving those results. Have a desire and the drive to win!',
    },
    20: {
      title: 'Treat Each Other Like Family',
      text:
        'Our relationships go deeper than simply being teammates at work. We genuinely care for and about each other. Whether it’s a kind word during a tough stretch, a friendly smile each morning, or a helping hand in stressful times, show your compassion.',
    },
    21: {
      title: 'Share Information',
      text:
        'With appropriate respect for confidentiality, share information freely throughout our organization. Always explain the context; the ‘why.’ The more people know, the better we can collaborate. Learn to ask yourself, “Who else needs to know this?” Share the good with the bad! Bad news doesn’t get better with age. ',
    },
    22: {
      title: 'Understand Our Purpose',
      text:
        'Seek to create the win/win. Understand our value proposition. Our job is to improve our customers’ business and profitability so that we also get rewarded. Understand our company’s strategy and your part of the plan. Is this something you should be working on? Does this align with our strategic plan? Be passionate about making things better, for all. ',
    },
    23: {
      title: 'Seize The Opportunity',
      text:
        'Problems and mistakes don’t define us. How we react to them does. Avoid analysis paralysis, gather the relevant facts, evaluate your options thoroughly, and act decisively. Our biggest chance to shine is when a teammate or customer is struggling or disappointed. See these challenges as opportunities, and do what it takes to amaze them.',
    },
    24: {
      title: 'Make Quality Personal',
      text:
        'Demonstrate a passion for excellence and take pride in the quality of everything you touch and everything you do. Have a healthy disdain for mediocrity. Good is not good enough. Always ask yourself, “Is this my best work?”',
    },
    25: {
      title: 'Renew And Refresh',
      text:
        'Take care of yourself at work and at home. Eat well, exercise, and get adequate sleep. Support each other in making healthy choices. The healthier you are, the more you’ll thrive personally and professionally. ',
    },
    26: {
      title: 'Keep Things Fun',
      text:
        'While our passion for excellence is real, remember that the world has bigger problems than the daily challenges that make up our work. Stuff happens. Keep perspective. Don’t take things personally or take yourself too seriously. Avoid drama, enjoy your work, and laugh every day.',
    },
  };

  if (fundNumber <= 26 && fundNumber > 0 && fund.hasOwnProperty(fundNumber)) {
    return fund[fundNumber];
  }
  return fund[1];
};
