import React from 'react';

import './Version.css';

const Version = (props) => {
  return <div id='software_version-outer'>v1.3.0</div>;
};

Version.propTypes = {};

export default Version;
