import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Solutions = ({ component: Component, auth, ...rest }) => {
  const { isAuthenticated = false, loading = true, user = {} } = auth;
  const { permissions = {} } = user;
  const { admin = false, solutions = false } = permissions;

  let toLoginScreen = false;

  if (!loading && !isAuthenticated) toLoginScreen = true;
  //check if this user has solutions privelage
  if (!loading && !solutions) toLoginScreen = true;

  //override for admin access
  if (!loading && !admin) toLoginScreen = true;

  return (
    <Route
      {...rest}
      render={(props) =>
        toLoginScreen ? (
          <Fragment>
            <Redirect to="/login" />
          </Fragment>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

Solutions.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Solutions);
