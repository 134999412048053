import {
  SOLUTIONS_DASHBOARD_LOADING,
  SOLUTIONS_DASHBOARD_RESULT,
  SOLUTIONS_DASHBOARD_ERROR,
} from "../actions/types";

const initialState = {
  loading: true,
  data: {},
  error: false,
  errorMsg: "",
};

export default function (state = initialState, action) {
  const { type, payload = {} } = action;

  switch (type) {
    //View All CUOs
    case SOLUTIONS_DASHBOARD_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
        data: {},
      };
    case SOLUTIONS_DASHBOARD_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: payload,
        data: {},
      };
    case SOLUTIONS_DASHBOARD_RESULT:
      return {
        ...state,
        loading: false,
        error: false,
        errorMsg: "",
        data: payload,
      };
    default:
      return state;
  }
}
