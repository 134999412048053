import {
  //View All CUOs
  SOLUTIONS_GETALLCUOS_RESULT,
  SOLUTIONS_GETALLCUOS_LOADING,
  SOLUTIONS_GETALLCUOS_ERROR,
  SOLUTIONS_GETERROREDCUOS_RESULT,
  SOLUTIONS_GETERROREDCUOS_LOADING,
  SOLUTIONS_GETERROREDCUOS_ERROR,

  //View All CROs
  SOLUTIONS_GETALLCROS_RESULT,
  SOLUTIONS_GETALLCROS_LOADING,
  SOLUTIONS_GETALLCROS_ERROR,
  SOLUTIONS_GETERROREDCROS_RESULT,
  SOLUTIONS_GETERROREDCROS_LOADING,
  SOLUTIONS_GETERROREDCROS_ERROR,

  //View Single CUO
  SOLUTIONS_GETCUO_ERROR,
  SOLUTIONS_GETCUO_SUCCESS,
  SOLUTIONS_GETCUO_SAVING,
  SOLUTIONS_GETCUO_LOADING,
  SOLUTIONS_GETCUO_RESULT,
  SOLUTIONS_GETCUO_SUBMITTING,
  SOLUTIONS_GETCUO_INVCHECK_RESULT,
  SOLUTIONS_GETCUO_INVCHECK_ERROR,
  SOLUTIONS_GETCUO_INVCHECK_LOADING,
  SOLUTIONS_GETCUO_PREFLIGHT_LOADING,
  SOLUTIONS_GETCUO_PREFLIGHT_RESULT,
  SOLUTIONS_GETCUO_PREFLIGHT_ERROR,

  //View Single CRO
  SOLUTIONS_GETCRO_ERROR,
  SOLUTIONS_GETCRO_SUCCESS,
  SOLUTIONS_GETCRO_SAVING,
  SOLUTIONS_GETCRO_LOADING,
  SOLUTIONS_GETCRO_RESULT,
  SOLUTIONS_GETCRO_SUBMITTING,
} from "../actions/types";

const initialState = {
  viewCUOs: {
    allCUOs: {
      loading: false,
      cuos: [],
      sortBy: "orderDate",
      sortDesc: "-1",
      totalCount: 0,
      pageLimit: 20,
    },
    erroredCUOs: {
      loading: true,
      cuos: [],
      sortBy: "orderDate",
      sortDesc: "-1",
      totalCount: 0,
      pageLimit: 20,
    },
  },
  viewCROs: {
    allCROs: {
      loading: false,
      cros: [],
      sortBy: "orderDate",
      sortDesc: "-1",
      totalCount: 0,
      pageLimit: 20,
    },
    erroredCROs: {
      loading: true,
      cros: [],
      sortBy: "orderDate",
      sortDesc: "-1",
      totalCount: 0,
      pageLimit: 20,
    },
  },
  viewSingleCUO: {
    cuoDetail: {},
    loading: true, //general loading screen
    saving: false, //displays saving screen
    success: false, //redirects to new page on true
    submitting: false, //shows a Submitting page, gets overwritten on "loading"
    errorMsg: "", //displays an alert
    invCheckLoading: false, //loading for invCheck
    invCheckItems: [],
    invCheckSufficient: false,
    invCheckError: false,
    preFlightLoading: false,
    preFlightRes: {},
    preFlightError: false,
  },
  viewSingleCRO: {
    croDetail: {},
    loading: true, //general loading screen
    saving: false, //displays saving screen
    success: false, //redirects to new page on true
    submitting: false, //shows a Submitting page, gets overwritten on "loading"
    errorMsg: "", //displays an alert
  },
};

export default function (state = initialState, action) {
  const { type, payload = {} } = action;

  //desctructure for the view All CUOs + CROs screen
  const {
    cuos = [],
    cros = [],
    page = 1,
    sortBy = "orderDate",
    sortDesc = "-1",
    totalCount = 0,
    pageLimit = 40,
  } = payload;

  switch (type) {
    //View All CUOs
    case SOLUTIONS_GETALLCUOS_LOADING:
      return {
        ...state,
        viewCUOs: {
          ...state.viewCUOs,
          allCUOs: {
            ...state.viewCUOs.allCUOs,
            loading: true,
            cuos: [],
          },
        },
        viewSingleCUO: {
          ...state.viewSingleCUO,
          success: false,
        },
      };

    case SOLUTIONS_GETALLCUOS_ERROR:
      return {
        ...state,
        viewCUOs: {
          ...state.viewCUOs,
          allCUOs: {
            ...state.viewCUOs.allCUOs,
            loading: false,
            cuos: [],
          },
        },
      };
    case SOLUTIONS_GETALLCUOS_RESULT:
      return {
        ...state,
        viewCUOs: {
          ...state.viewCUOs,
          allCUOs: {
            ...state.viewCUOs.allCUOs,
            loading: false,
            cuos,
            page,
            totalCount,
            pageLimit,
            sortBy,
            sortDesc,
          },
        },
      };
    case SOLUTIONS_GETERROREDCUOS_LOADING:
      return {
        ...state,
        viewCUOs: {
          ...state.viewCUOs,
          erroredCUOs: {
            ...state.viewCUOs.erroredCUOs,
            loading: true,
            cuos: [],
          },
        },
      };
    case SOLUTIONS_GETERROREDCUOS_ERROR:
      return {
        ...state,
        viewCUOs: {
          ...state.viewCUOs,
          erroredCUOs: {
            ...state.viewCUOs.erroredCUOs,
            loading: false,
            cuos: [],
          },
        },
      };
    case SOLUTIONS_GETERROREDCUOS_RESULT:
      return {
        ...state,
        viewCUOs: {
          ...state.viewCUOs,
          erroredCUOs: {
            ...state.viewCUOs.erroredCUOs,
            loading: false,
            cuos,
            page,
            pageLimit,
            sortBy,
            sortDesc,
            totalCount,
          },
        },
      };

    //View All CROs
    case SOLUTIONS_GETALLCROS_LOADING:
      return {
        ...state,
        viewCROs: {
          ...state.viewCROs,
          allCROs: {
            ...state.viewCROs.allCROs,
            loading: true,
            cros: [],
          },
        },
        viewSingleCRO: {
          ...state.viewSingleCRO,
          success: false,
        },
      };

    case SOLUTIONS_GETALLCROS_ERROR:
      return {
        ...state,
        viewCROs: {
          ...state.viewCROs,
          allCROs: {
            ...state.viewCUOs.allCROs,
            loading: false,
            cros: [],
          },
        },
      };
    case SOLUTIONS_GETALLCROS_RESULT:
      return {
        ...state,
        viewCROs: {
          ...state.viewCROs,
          allCROs: {
            ...state.viewCROs.allCROs,
            loading: false,
            cros,
            page,
            totalCount,
            pageLimit,
            sortBy,
            sortDesc,
          },
        },
      };
    case SOLUTIONS_GETERROREDCROS_LOADING:
      return {
        ...state,
        viewCROs: {
          ...state.viewCROs,
          erroredCROs: {
            ...state.viewCROs.erroredCROs,
            loading: true,
            cros: [],
          },
        },
      };
    case SOLUTIONS_GETERROREDCROS_ERROR:
      return {
        ...state,
        viewCROs: {
          ...state.viewCROs,
          erroredCROs: {
            ...state.viewCROs.erroredCROs,
            loading: false,
            cros: [],
          },
        },
      };
    case SOLUTIONS_GETERROREDCROS_RESULT:
      return {
        ...state,
        viewCROs: {
          ...state.viewCROs,
          erroredCROs: {
            ...state.viewCROs.erroredCROs,
            loading: false,
            cros,
            page,
            pageLimit,
            sortBy,
            sortDesc,
            totalCount,
          },
        },
      };

    //View Single CUO
    case SOLUTIONS_GETCUO_INVCHECK_RESULT: {
      return {
        ...state,
        viewSingleCUO: {
          ...state.viewSingleCUO,
          invCheckLoading: false,
          invCheckItems: payload.items,
          invCheckSufficient: payload.sufficientInventory,
          invCheckError: false,
        },
      };
    }
    case SOLUTIONS_GETCUO_INVCHECK_ERROR: {
      return {
        ...state,
        viewSingleCUO: {
          ...state.viewSingleCUO,
          invCheckLoading: false,
          invCheckItems: [],
          invCheckSufficient: false,
          invCheckError: true,
        },
      };
    }
    case SOLUTIONS_GETCUO_INVCHECK_LOADING: {
      return {
        ...state,
        viewSingleCUO: {
          ...state.viewSingleCUO,
          invCheckLoading: true,
          invCheckItems: [],
          invCheckSufficient: false,
          invCheckError: false,
        },
      };
    }
    case SOLUTIONS_GETCUO_PREFLIGHT_LOADING: {
      return {
        ...state,
        viewSingleCUO: {
          ...state.viewSingleCUO,
          preFlightLoading: true,
          preFlightRes: {},
          preFlightError: false,
        },
      };
    }
    case SOLUTIONS_GETCUO_PREFLIGHT_RESULT: {
      return {
        ...state,
        viewSingleCUO: {
          ...state.viewSingleCUO,
          preFlightLoading: false,
          preFlightRes: payload,
          preFlightError: false,
        },
      };
    }
    case SOLUTIONS_GETCUO_PREFLIGHT_ERROR: {
      return {
        ...state,
        viewSingleCUO: {
          ...state.viewSingleCUO,
          preFlightLoading: false,
          preFlightRes: {},
          preFlightError: true,
        },
      };
    }
    case SOLUTIONS_GETCUO_RESULT: {
      return {
        ...state,
        viewSingleCUO: {
          ...state.viewSingleCUO,
          loading: false,
          saving: false,
          cuoDetail: payload,
        },
      };
    }
    case SOLUTIONS_GETCUO_LOADING: {
      return {
        ...state,
        viewSingleCUO: {
          ...state.viewSingleCUO,
          loading: true,
          saving: false,
          success: false,
          submitting: false,
          errorMsg: "",
          successMsg: "",
        },
      };
    }
    case SOLUTIONS_GETCUO_ERROR: {
      return {
        ...state,
        viewSingleCUO: {
          ...state.viewSingleCUO,
          loading: false,
          saving: false,
          success: false,
          errorMsg: payload,
        },
      };
    }
    case SOLUTIONS_GETCUO_SUCCESS: {
      return {
        ...state,
        viewSingleCUO: {
          ...state.viewSingleCUO,
          loading: false,
          saving: false,
          success: true,
          errorMsg: "",
        },
      };
    }
    case SOLUTIONS_GETCUO_SAVING: {
      return {
        ...state,
        viewSingleCUO: {
          ...state.viewSingleCUO,
          loading: false,
          saving: true,
          success: false,
          errorMsg: "",
        },
      };
    }
    case SOLUTIONS_GETCUO_SUBMITTING: {
      return {
        ...state,
        viewSingleCUO: {
          ...state.viewSingleCUO,
          loading: false,
          saving: false,
          success: false,
          submitting: true,
          errorMsg: "",
        },
      };
    }

    //View Single CRO
    case SOLUTIONS_GETCRO_RESULT: {
      return {
        ...state,
        viewSingleCRO: {
          ...state.viewSingleCRO,
          loading: false,
          saving: false,
          croDetail: payload,
        },
      };
    }
    case SOLUTIONS_GETCRO_LOADING: {
      return {
        ...state,
        viewSingleCRO: {
          ...state.viewSingleCRO,
          loading: true,
          saving: false,
          success: false,
          submitting: false,
          errorMsg: "",
          successMsg: "",
        },
      };
    }
    case SOLUTIONS_GETCRO_ERROR: {
      return {
        ...state,
        viewSingleCRO: {
          ...state.viewSingleCRO,
          loading: false,
          saving: false,
          success: false,
          errorMsg: payload,
        },
      };
    }
    case SOLUTIONS_GETCRO_SUCCESS: {
      return {
        ...state,
        viewSingleCRO: {
          ...state.viewSingleCRO,
          loading: false,
          saving: false,
          success: true,
          errorMsg: "",
        },
      };
    }
    case SOLUTIONS_GETCRO_SAVING: {
      return {
        ...state,
        viewSingleCRO: {
          ...state.viewSingleCRO,
          loading: false,
          saving: true,
          success: false,
          errorMsg: "",
        },
      };
    }
    case SOLUTIONS_GETCRO_SUBMITTING: {
      return {
        ...state,
        viewSingleCRO: {
          ...state.viewSingleCRO,
          loading: false,
          saving: false,
          success: false,
          submitting: true,
          errorMsg: "",
        },
      };
    }

    default:
      return state;
  }
}
