import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';

import { setAlert } from '../../../../../actions/alert';
import { modifyUserPassword } from '../../../../../actions/userSettings';

import PageHeading from '../../../../layout/PageHeading/PageHeading';
import BottomShadow from '../../../../layout/BottomShadow/BottomShadow';
import Spinner from '../../../../layout/Spinner';
import './AccountSettings.css';

const AccountSettings = ({
  setAlert,
  modifyUserPassword,
  auth,
  userSettings,
}) => {
  const { loading = true, user = {} } = auth;
  const { firstName = '', lastName = '', email = '' } = user;
  const {
    loading: userStateLoading = true,
    errorMsg = '',
    success = false,
    successMsg = '',
  } = userSettings;

  const [profileState, setProfileState] = useState({
    oldPassword: '',
    password: '',
    verifyPassword: '',
  });
  const [profileErrors, setProfileErrors] = useState({
    _err_oldPassword: '',
    _err_password: '',
    _err_verifyPassword: '',
  });
  const { oldPassword, password, verifyPassword } = profileState;
  const {
    _err_oldPassword = '',
    _err_password = '',
    _err_verifyPassword = '',
  } = profileErrors;

  useEffect(() => {
    if (success) {
      setProfileState({
        ...profileState,
        oldPassword: '',
        password: '',
        verifyPassword: '',
      });
    }
    // eslint-disable-next-line
  }, [success]);

  const onChangeInput = (e) => {
    setProfileState({
      ...profileState,
      [e.target.name]: e.target.value,
    });
  };

  const onBlur = (e) => {
    setProfileErrors({
      _err_oldPassword: '',
      _err_password: '',
      _err_verifyPassword: '',
    });

    if (e.target.name === 'oldPassword') {
      if (e.target.value === '' || e.target.value === ' ') {
        setProfileErrors({
          ...profileErrors,
          _err_oldPassword: 'Please enter your old/current password to proceed',
        });
      } else {
        setProfileErrors({
          ...profileErrors,
          _err_oldPassword: '',
        });
      }
    }

    //determine the password strength
    if (e.target.name === 'password') {
      const strongRegex = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
      );

      if (!strongRegex.test(e.target.value)) {
        setProfileErrors({
          ...profileErrors,
          _err_password:
            'Password must have at least 1 uppercase letter, 1 number, 1 special character and be longer than 8 characters.',
        });
      } else {
        setProfileErrors({
          ...profileErrors,
          _err_password: '',
        });
      }
    }
    if (e.target.name === 'verifyPassword') {
      if (password !== e.target.value) {
        setProfileErrors({
          ...profileErrors,
          _err_verifyPassword: 'Verify Password and Password fields must match',
        });
      } else {
        setProfileErrors({
          ...profileErrors,
          _err_verifyPassword: '',
        });
      }
    }
  };

  const onSubmit = () => {
    if (oldPassword === '' || oldPassword === ' ') {
      setProfileErrors({
        ...profileErrors,
        _err_oldPassword: 'Please enter your old/current password to proceed',
      });
      setAlert('Please enter your old/current password to proceed', 'danger');
      return;
    }

    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );

    if (!strongRegex.test(password)) {
      setProfileErrors({
        ...profileErrors,
        _err_password:
          'Password must have at least 1 uppercase letter, 1 number, 1 special character and be longer than 8 characters.',
      });
      setAlert(
        'Password must have at least 1 uppercase letter, 1 number, 1 special character and be longer than 8 characters.',
        'danger'
      );
      return;
    }

    if (password !== verifyPassword) {
      setProfileErrors({
        ...profileErrors,
        _err_verifyPassword: 'Verify Password and Password fields must match',
      });
      setAlert('Verify Password and Password fields must match', 'danger');
      return;
    }

    console.log('Being Submitted');
    modifyUserPassword(profileState);
  };

  return (
    <div id='userprofile_accountsettings'>
      <div className='centerContainer'>
        <PageHeading
          config={{
            preHeading: 'Your Account',
            heading: 'Profile Settings',
            description: '',
            graphic: 'fas fa-user-cog',
          }}
        />
        <div className='contentBox sec1'>
          {loading || userStateLoading ? (
            <Spinner />
          ) : (
            <Fragment>
              <div className='inputDiv'>
                First Name: <br />
                <input type='text' value={firstName} locked />
              </div>
              <div className='inputDiv'>
                Last Name: <br />
                <input type='text' value={lastName} locked />
              </div>
              <div className='inputDiv'>
                Email: <br />
                <input type='text' value={email} locked />
              </div>
              <div className='inputDiv'>
                Old Password: <br />
                <input
                  type='password'
                  name='oldPassword'
                  value={oldPassword}
                  minLength='6'
                  onChange={(e) => onChangeInput(e)}
                  onBlur={(e) => onBlur(e)}
                />
                {_err_oldPassword !== '' && (
                  <div className='inputError'>{_err_oldPassword}</div>
                )}
              </div>
              <div className='inputDiv'>
                New Password: <br />
                <input
                  type='password'
                  name='password'
                  value={password}
                  minLength='6'
                  onChange={(e) => onChangeInput(e)}
                  onBlur={(e) => onBlur(e)}
                />
                {_err_password !== '' && (
                  <div className='inputError'>{_err_password}</div>
                )}
              </div>
              <div className='inputDiv'>
                Confirm Password: <br />
                <input
                  type='password'
                  name='verifyPassword'
                  value={verifyPassword}
                  minLength='6'
                  onChange={(e) => onChangeInput(e)}
                  onBlur={(e) => onBlur(e)}
                />
                {_err_verifyPassword !== '' && (
                  <div className='inputError'>{_err_verifyPassword}</div>
                )}
              </div>
              {!success && errorMsg !== '' && (
                <div className='formErrorMsg'>{errorMsg}</div>
              )}
              {success && successMsg !== '' && (
                <div className='formSuccessMsg'>{successMsg}</div>
              )}
              <div
                className={
                  _err_oldPassword === '' &&
                  _err_password === '' &&
                  _err_verifyPassword === ''
                    ? 'actionBtns'
                    : 'actionBtns greyOut'
                }
              >
                <input
                  type='submit'
                  id='saveButton'
                  value='Save Password'
                  onClick={(e) => onSubmit()}
                />
              </div>
            </Fragment>
          )}
        </div>
        <BottomShadow />
      </div>
    </div>
  );
};

AccountSettings.propTypes = {};

const mapStateToProps = (state) => ({
  auth: state.auth,
  userSettings: state.userSettings,
});

export default connect(mapStateToProps, { setAlert, modifyUserPassword })(
  AccountSettings
);
