import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import {
  pullFromMiddleware,
  pushProductsIntoElastic,
  temp_p21SFInit,
  temp_p21SFDelta,
} from '../../../../actions/bulkFuzzyMatch';

const TempTools = ({
  pullFromMiddleware,
  pushProductsIntoElastic,
  temp_p21SFInit,
  temp_p21SFDelta,
}) => {
  return (
    <Fragment>
      <div>
        <span onClick={(e) => pullFromMiddleware()}>
          Pull P21 Products into Mongo
        </span>
        <br />
        <br />
        <span onClick={(e) => pushProductsIntoElastic()}>
          Push products into elastic Search DB
        </span>
        <br />
        <br />
        <span onClick={(e) => temp_p21SFInit()}>P21{'<->'}SF Init</span>
        <br />
        <br />
        <span onClick={(e) => temp_p21SFDelta()}>P21{'<->'}SF Delta</span>
      </div>
      <br />
      <br />

      <div className='resp-table'>
        <div className='resp-table-header'>
          <div className='resp-table-row'>
            <div className='table-header-cell'>Table Header</div>
            <div className='table-header-cell'>Table Header</div>
            <div className='table-header-cell'>Table Header</div>
          </div>
        </div>
        <div className='resp-table-body'>
          <div className='resp-table-row'>
            <div className='table-body-cell'>Table Cell</div>
            <div className='table-body-cell'>Table Cell</div>
            <div className='table-body-cell'>Table Cell</div>
          </div>
          <div className='resp-table-row'>
            <div className='table-body-cell'>Table Cell</div>
            <div className='table-body-cell'>Table Cell</div>
            <div className='table-body-cell'>Table Cell</div>
          </div>
          <div className='resp-table-row'>
            <div className='table-body-cell'>Table Cell</div>
            <div className='table-body-cell'>Table Cell</div>
            <div className='table-body-cell'>Table Cell</div>
          </div>
          <div className='resp-table-row'>
            <div className='table-body-cell'>Table Cell</div>
            <div className='table-body-cell'>Table Cell</div>
            <div className='table-body-cell'>Table Cell</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  pullFromMiddleware,
  pushProductsIntoElastic,
  temp_p21SFInit,
  temp_p21SFDelta,
})(TempTools);
