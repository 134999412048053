export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//User Management Types
//export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
//export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const USER_RESETCODEVALID = "USER_RESETCODEVALID";
export const USER_RESETCODEINVALID = "USER_RESETCODEINVALID";
export const USER_RESETCODELOADING = "USER_RESETCODELOADING";

//USER PROFILE SETTINGS TYPES
export const USERSETTINGS_CHANGEPASSWORD = "USERSETTINGS_CHANGEPASSWORD";
export const USERSETTINGS_LOADING = "USERSETTINGS_LOADING";
export const USERSETTINGS_ERROR = "USERSETTINGS_ERROR";

//PUBLIC Order Expedite Screen
export const EXPEDITE_LOADING = "EXPEDITE_LOADING";
export const EXPEDITE_RECEIVED = "EXPEDITE_RECEIVED";
export const EXPEDITE_ERROR = "EXPEDITE_ERROR";
export const EXPEDITE_CLEAR = "EXPEDITE_CLEAR";
export const EXPEDITE_LOADORDER = "EXPEDITE_LOADORDER";
export const EXPEDITE_RECEIVEDORDER = "EXPEDITE_RECEIVEDORDER";
export const EXPEDITE_VIEW_LINEITEMS = "EXPEDITE_VIEW_LINEITEMS";
export const EXPEDITE_VIEW_TRACKING = "EXPEDITE_VIEW_TRACKING";

//SearchAsYouType
export const SEARCH_ASTYPE_RESULT = "SEARCH_ASTYPE_RESULT";
export const SEARCH_ASTYPE_CLEAR = "SEARCH_ASTYPE_CLEAR";
export const SEARCH_ASTYPE_SHOW = "SEARCH_ASTYPE_SHOW";
export const SEARCH_ASTYPE_HIDE = "SEARCH_ASTYPE_HIDE";

//SearchAsYouType for Company index
export const SEARCH_COMPANY_ASTYPE_RESULT = "SEARCH_COMPANY_ASTYPE_RESULT";
export const SEARCH_COMPANY_ASTYPE_CLEAR = "SEARCH_COMPANY_ASTYPE_CLEAR";
export const SEARCH_COMPANY_ASTYPE_SHOW = "SEARCH_COMPANY_ASTYPE_SHOW";
export const SEARCH_COMPANY_ASTYPE_HIDE = "SEARCH_COMPANY_ASTYPE_HIDE";

//SearchAsYouType for ShipTo index
export const SEARCH_SHIPTO_ASTYPE_RESULT = "SEARCH_SHIPTO_ASTYPE_RESULT";
export const SEARCH_SHIPTO_ASTYPE_CLEAR = "SEARCH_SHIPTO_ASTYPE_CLEAR";
export const SEARCH_SHIPTO_ASTYPE_SHOW = "SEARCH_SHIPTO_ASTYPE_SHOW";
export const SEARCH_SHIPTO_ASTYPE_HIDE = "SEARCH_SHIPTO_ASTYPE_HIDE";

//SearchFullType
export const SEARCH_FULLTEXT_LOADING = "SEARCH_FULLTEXT_LOADING";
export const SEARCH_FULLTEXT_RESULT = "SEARCH_FULLTEXT_RESULT";
export const SEARCH_GET_P21_QTY = "SEARCH_GET_P21_QTY";
export const SEARCH_GET_P21_PRICEAVAIL = "SEARCH_GET_P21_PRICEAVAIL";
export const SEARCH_LOADING_P21_PRICEAVAIL = "SEARCH_LOADING_P21_PRICEAVAIL";
export const SEARCH_LOADING_PRODDETAILS = "SEARCH_LOADING_PRODDETAILS";
export const SEARCH_GET_PRODDETAILS = "SEARCH_GET_PRODDETAILS";

//Bulk Product Fuzzy Match Types
export const BULKFUZZY_FORM_LOADING = "BULKFUZZY_FORM_LOADING";
export const BULKFUZZY_FORM_ERROR = "BULKFUZZY_FORM_ERROR";
export const BULKFUZZY_FORM_SUCCESS = "BULKFUZZY_FORM_SUCCESS";
export const BULKFUZZY_FORM_RESET = "BULKFUZZY_FORM_RESET";
export const BULKFUZZY_TABLE_LOADING = "BULKFUZZY_TABLE_LOADING";
export const BULKFUZZY_TABLE_RESULT = "BULKFUZZY_TABLE_RESULT";

//Solutions Types
//ViewAllCUOs
export const SOLUTIONS_GETALLCUOS_RESULT = "SOLUTIONS_GETALLCUOS_RESULT";
export const SOLUTIONS_GETALLCUOS_LOADING = "SOLUTIONS_GETALLCUOS_LOADING";
export const SOLUTIONS_GETALLCUOS_ERROR = "SOLUTIONS_GETALLCUOS_ERROR";
export const SOLUTIONS_GETERROREDCUOS_RESULT =
  "SOLUTIONS_GETERROREDCUOS_RESULT";
export const SOLUTIONS_GETERROREDCUOS_LOADING =
  "SOLUTIONS_GETERROREDCUOS_LOADING";
export const SOLUTIONS_GETERROREDCUOS_ERROR = "SOLUTIONS_GETERROREDCUOS_ERROR";

//ViewAllCROs
export const SOLUTIONS_GETALLCROS_RESULT = "SOLUTIONS_GETALLCROS_RESULT";
export const SOLUTIONS_GETALLCROS_LOADING = "SOLUTIONS_GETALLCROS_LOADING";
export const SOLUTIONS_GETALLCROS_ERROR = "SOLUTIONS_GETALLCROS_ERROR";
export const SOLUTIONS_GETERROREDCROS_RESULT =
  "SOLUTIONS_GETERROREDCROS_RESULT";
export const SOLUTIONS_GETERROREDCROS_LOADING =
  "SOLUTIONS_GETERROREDCROS_LOADING";
export const SOLUTIONS_GETERROREDCROS_ERROR = "SOLUTIONS_GETERROREDCROS_ERROR";

//SINGLE CUO (ViewCUO)
export const SOLUTIONS_GETCUO_ERROR = "SOLUTIONS_GETCUO_ERROR";
export const SOLUTIONS_GETCUO_SUCCESS = "SOLUTIONS_GETCUO_SUCCESS";
export const SOLUTIONS_GETCUO_LOADING = "SOLUTIONS_GETCUO_LOADING";
export const SOLUTIONS_GETCUO_RESULT = "SOLUTIONS_GETCUO_RESULT";
export const SOLUTIONS_GETCUO_SAVING = "SOLUTIONS_GETCUO_SAVING";
export const SOLUTIONS_GETCUO_SUBMITTING = "SOLUTIONS_GETCUO_SUBMITTING";
export const SOLUTIONS_GETCUO_INVCHECK_RESULT =
  "SOLUTIONS_GETCUO_INVCHECK_RESULT";
export const SOLUTIONS_GETCUO_INVCHECK_ERROR =
  "SOLUTIONS_GETCUO_INVCHECK_ERROR";
export const SOLUTIONS_GETCUO_INVCHECK_LOADING =
  "SOLUTIONS_GETCUO_INVCHECK_LOADING";
export const SOLUTIONS_GETCUO_PREFLIGHT_LOADING =
  "SOLUTIONS_GETCUO_PREFLIGHT_LOADING";
export const SOLUTIONS_GETCUO_PREFLIGHT_RESULT =
  "SOLUTIONS_GETCUO_PREFLIGHT_RESULT";
export const SOLUTIONS_GETCUO_PREFLIGHT_ERROR =
  "SOLUTIONS_GETCUO_PREFLIGHT_ERROR";

//SINGLE CRO (ViewCRO)
export const SOLUTIONS_GETCRO_ERROR = "SOLUTIONS_GETCRO_ERROR";
export const SOLUTIONS_GETCRO_SUCCESS = "SOLUTIONS_GETCRO_SUCCESS";
export const SOLUTIONS_GETCRO_LOADING = "SOLUTIONS_GETCRO_LOADING";
export const SOLUTIONS_GETCRO_RESULT = "SOLUTIONS_GETCRO_RESULT";
export const SOLUTIONS_GETCRO_SAVING = "SOLUTIONS_GETCRO_SAVING";
export const SOLUTIONS_GETCRO_SUBMITTING = "SOLUTIONS_GETCRO_SUBMITTING";

//Solutions pre-flight data uploader
export const PREFLIGHT_FORM_LOADING = "PREFLIGHT_FORM_LOADING";
export const PREFLIGHT_FORM_ERROR = "PREFLIGHT_FORM_ERROR";
export const PREFLIGHT_FORM_SUCCESS = "PREFLIGHT_FORM_SUCCESS";
export const PREFLIGHT_FORM_RESET = "PREFLIGHT_FORM_RESET";
export const PREFLIGHT_TABLE_LOADING = "PREFLIGHT_TABLE_LOADING";
export const PREFLIGHT_TABLE_RESULT = "PREFLIGHT_TABLE_RESULT";

//Solutions Dashboard
export const SOLUTIONS_DASHBOARD_LOADING = "SOLUTIONS_DASHBOARD_LOADING";
export const SOLUTIONS_DASHBOARD_RESULT = "SOLUTIONS_DASHBOARD_RESULT";
export const SOLUTIONS_DASHBOARD_ERROR = "SOLUTIONS_DASHBOARD_ERROR";

//LAYOUT Types
export const SIDEDRAWER_OPEN = "SIDEDRAWER_OPEN";
export const SIDEDRAWER_CLOSE = "SIDEDRAWER_CLOSE";
export const BACKDROP_SHOW = "BACKDROP_SHOW";
export const BACKDROP_HIDE = "BACKDROP_HIDE";
export const ASYOUTYPE_SHOW = "ASYOUTYPE_SHOW";
export const ASYOUTYPE_HIDE = "ASYOUTYPE_HIDE";
export const RESET_NAV = "RESET_NAV";
export const NAVDROPDOWN_SHOW = "NAVDROPDOWN_SHOW";
export const NAVDROPDOWN_HIDE = "NAVDROPDOWN_HIDE";

//APP DATA - ADMIN SFSync
export const SFSYNC_LOADING = "SFSYNC_LOADING";
export const SFSYNC_RESULT = "SFSYNC_RESULT";
export const SFSYNC_SAVED = "SFSYNC_SAVED";
export const SFSYNC_CLEARSAVED = "SFSYNC_CLEARSAVED";
export const SFSYNC_ERROR = "SFSYNC_ERROR";
export const SFSYNC_LOGRESULT = "SFSYNC_LOGRESULT";
export const SFSYNC_LOGERROR = "SFSYNC_LOGERROR";

//ADMIN - ALL USERS MANAGE
export const ALLUSERS_LOADING = "ALLUSERS_LOADING";
export const ALLUSERS_RESULTS = "ALLUSERS_RESULTS";
export const ALLUSERS_ERROR = "ALLUSERS_ERROR";
export const ALLUSERS_RESETPASSWORD = "ALLUSERS_RESETPASSWORD";
