import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { formatMoney } from '../../../utils/currencyFormat';

const ProdFuzzyMatch_singleProdDetails = ({
  elasticInfo = {},
  p21Info = {},
}) => {
  const {
    unitPrice = 0,
    priceUOM = 'EA',
    priceUnitSize = 1,
    availabilityByLocation = [],
    extended_desc = '',
  } = p21Info;
  const {
    item_id = '',
    manufacturer_name = '',
    supplier_id = '',
    supplier_name = '',
    ad_long_desc = '',
  } = elasticInfo;

  return (
    <div className='singleSearch_itemDetails'>
      <div className='p21_partNumber mobileOnly'>
        <b>P21 Part Number:</b> {item_id}
      </div>
      <div className='manu_name mobileOnly'>
        <b>Manufacturer:</b> {manufacturer_name}
      </div>

      <div className='long_desc'>
        <b>Description:</b> {ad_long_desc}
        <br />
        <a
          href={`https://www.google.com/search?q=${item_id} ${manufacturer_name}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Search Google For More Info
        </a>
      </div>
      <div className='supplierInfo'>
        {supplier_name !== null && supplier_id !== null && (
          <Fragment>
            Supplier:{' '}
            {supplier_name !== null
              ? supplier_name.replace(/\b\w/g, (c) => c.toUpperCase())
              : ''}{' '}
            (ID: {supplier_id})
          </Fragment>
        )}
      </div>
      <div className='priceInfo'>
        Price: ${formatMoney(unitPrice)} {priceUOM} Unit Size: {priceUnitSize}{' '}
        {extended_desc !== null && (
          <Fragment>
            {'('}
            {extended_desc}
            {')'}
          </Fragment>
        )}
      </div>
      <div className='item_availability'>
        {availabilityByLocation.length > 0 &&
          availabilityByLocation.map((location) => {
            const { locationName = '', quantity = 0 } = location;
            return (
              <div className='loc-outer' key={uuidv4()}>
                <div className='loc-qty'>{quantity}</div>
                <div className='loc-name'>{locationName}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

ProdFuzzyMatch_singleProdDetails.propTypes = {
  elasticInfo: PropTypes.object.isRequired,
  p21Info: PropTypes.object.isRequired,
};

export default ProdFuzzyMatch_singleProdDetails;
