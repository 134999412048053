import {
  USERSETTINGS_LOADING,
  USERSETTINGS_CHANGEPASSWORD,
  USERSETTINGS_ERROR,
} from '../actions/types';

const initialState = {
  loading: false,
  success: false,
  errorMsg: '',
  successMsg: '',
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USERSETTINGS_LOADING:
      return {
        ...state,
        loading: true,
        success: false,
        errorMsg: '',
        successMsg: '',
      };
    case USERSETTINGS_CHANGEPASSWORD:
      return {
        ...state,
        loading: false,
        success: true,
        successMsg: payload,
      };
    case USERSETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
}
