import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ProdFuzzyMatch_singleOverlay_sec3 = ({ search_state = {} }) => {
  const { emp_prodfuzzy_fulltext_productdetails = {} } = search_state;

  const {
    ADData = {},
    manufacturerData = {},
  } = emp_prodfuzzy_fulltext_productdetails;

  const {
    item_features = [],
    marketing_description = '',
    alt_part_numbers = [],
    upc = [],
    unspsc = [],
    gtin = [],
    productAttributes = [],
    productAssets = [],
  } = ADData;

  const { manu_part_number = '', includes = '' } = manufacturerData;

  return (
    <div className='sec-outer'>
      <div className='secTitle'>Product Info</div>
      <div className='secContent'>
        <div className='content'>
          <div
            style={{
              paddingTop: '5px',
              marginBottom: '5px',
              fontWeight: 'bold',
            }}
          >
            Product Description:
          </div>
          {(marketing_description === '' || marketing_description === null) && (
            <div style={{ paddingLeft: '10px' }}>
              <i>No Description Found</i>
            </div>
          )}{' '}
          {marketing_description !== '' && marketing_description !== null && (
            <Fragment>{marketing_description}</Fragment>
          )}
        </div>

        <div className='content'>
          <div style={{ fontWeight: 'bold', paddingTop: '10px' }}>
            Features:
          </div>
          {item_features.length > 0 && (
            <ul>
              {item_features.map((feature, i) => {
                return <li key={i}>{feature}</li>;
              })}
            </ul>
          )}
        </div>

        {includes !== null && includes !== '' && (
          <div className='content'>Includes: {includes}</div>
        )}
      </div>
      <div className='secContent flex'>
        <div className='flex-inner'>
          <div className='title'>Product Attributes</div>
          <div className='content'>
            <table width='100%' cellPadding='0' cellSpacing='0'>
              <tbody>
                {productAttributes.length === 0 && (
                  <tr>
                    <td>
                      <div style={{ paddingTop: '5px', paddingLeft: '10px' }}>
                        <i>No Attributes Found</i>
                      </div>
                    </td>
                  </tr>
                )}
                {productAttributes.length > 0 &&
                  productAttributes.map((attr, i) => {
                    const { name = '', value = '' } = attr;
                    return (
                      <tr key={i}>
                        <td>{name}</td>
                        <td>{value}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='flex-inner'>
          <div className='title'>Part Numbers</div>
          <div className='content'>
            <table width='100%' cellPadding='0' cellSpacing='0'>
              <tbody>
                <tr>
                  <td>Mfg. #</td>
                  <td>{manu_part_number === '' ? ' -' : manu_part_number}</td>
                </tr>
                <tr>
                  <td>UNSPSC</td>
                  <td>
                    {unspsc.length === 0 && ' -'}
                    {unspsc.length > 0 &&
                      unspsc.map((num) => {
                        return num;
                      })}
                  </td>
                </tr>
                <tr>
                  <td>GTIN</td>
                  <td>
                    {gtin.length === 0 && ' -'}
                    {gtin.length > 0 &&
                      gtin.map((num) => {
                        return num;
                      })}
                  </td>
                </tr>
                <tr>
                  <td>UPC</td>
                  <td>
                    {upc.length === 0 && ' -'}
                    {upc.length > 0 &&
                      upc.map((num) => {
                        return num;
                      })}
                  </td>
                </tr>
                <tr>
                  <td>Alt Nos.</td>
                  <td>
                    {alt_part_numbers.length === 0 && ' -'}
                    {alt_part_numbers.length > 0 &&
                      alt_part_numbers.map((num, i) => {
                        if (i > 0) {
                          return <Fragment key={i}>, {num}</Fragment>;
                        }
                        return num;
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='secContent productAssets'>
        <div className='title'>Product Assets</div>
        <div className='content'>
          {productAssets.length === 0 && (
            <div style={{ paddingTop: '5px', paddingLeft: '10px' }}>
              <i>No Assets Found</i>
            </div>
          )}
          {productAssets.length > 0 &&
            productAssets.map((asset, i) => {
              const {
                assetType = '',
                assetName = '',
                assetCaption = '',
              } = asset;
              return assetType === 'URL' ? (
                <div className='asset-flex-outer' key={i}>
                  <div>
                    <a
                      href={assetName}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {assetCaption}
                    </a>
                  </div>
                  <div>
                    <a
                      href={assetName}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      View
                    </a>
                  </div>
                </div>
              ) : (
                <div className='asset-flex-outer' key={i}>
                  <div>
                    <a
                      href={`https://cx1.unilogcorp.com/cx1/view/api/asset/v1/dam/assets/ITEM/DOC/file/2/${assetName}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {assetCaption}
                    </a>
                  </div>
                  <div>
                    <a
                      href={`https://cx1.unilogcorp.com/cx1/view/api/asset/v1/dam/assets/ITEM/DOC/file/2/${assetName}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Download
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

ProdFuzzyMatch_singleOverlay_sec3.propTypes = {
  search_state: PropTypes.object.isRequired,
};

export default ProdFuzzyMatch_singleOverlay_sec3;
