import axios from "axios";
import { ALLUSERS_LOADING, ALLUSERS_RESULTS, ALLUSERS_ERROR } from "./types";
import { setAlert } from "./alert";

export const loadAllUsers = () => async (dispatch) => {
  dispatch({ type: ALLUSERS_LOADING });

  try {
    //load from the backend
    const payload = await axios.get("/adminAPI/users");

    dispatch({ type: ALLUSERS_RESULTS, payload: payload.data });
  } catch (error) {
    dispatch({ type: ALLUSERS_ERROR, payload: error.message });
  }
};

export const resetUserPassword = (_id, newPassword) => async (dispatch) => {
  try {
    //load from the backend
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ _id, newPassword });
    const res = await axios.post(`/adminAPI/users/setpassword`, body, config);
    if (res.status === 200) {
      dispatch(setAlert("Users password has been reset", "success", "5000"));
      return;
    }

    //announce the save was a success
    dispatch(setAlert("Unable to reset users password", "warn", "5000"));
  } catch (error) {
    //announce the save was a success
    dispatch(setAlert("Unable to reset users password", "warn", "5000"));
  }
};

export const togglePermission =
  (_id, permission, reqState) => async (dispatch) => {
    console.log("actioned");
    try {
      //load from the backend
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({ _id, permission, reqState });
      const res = await axios.post(
        `/adminAPI/users/togglePermission`,
        body,
        config
      );
      if (res.status === 200) {
        dispatch(setAlert("Permission set", "success", "5000"));

        //refresh out entire user list
        dispatch({ type: ALLUSERS_RESULTS, payload: res.data });
        return;
      }

      //announce the save was a success
      dispatch(setAlert("Unable to set permission", "warn", "5000"));
    } catch (error) {
      //announce the save was a success
      dispatch(setAlert("Unable to set permission", "warn", "5000"));
    }
  };
