import {
  ALLUSERS_LOADING,
  ALLUSERS_RESULTS,
  ALLUSERS_ERROR,
} from "../actions/types";

const initialState = {
  loading: true,
  error: false,
  users: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALLUSERS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        users: [],
      };
    case ALLUSERS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        users: [],
      };
    case ALLUSERS_RESULTS:
      return {
        ...state,
        loading: false,
        error: false,
        users: payload,
      };
    default:
      return state;
  }
}
