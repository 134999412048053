import React from "react";
import PropTypes from "prop-types";

import "./ResetPassword.css";

const ResetPassword = ({
  allUsers,
  onPasswordFieldChange,
  execPasswordReset,
  closePasswordReset,
}) => {
  const { resetPassword = "" } = allUsers;

  return (
    <div id="allUsersResetPassword-outer">
      <div id="allUsersResetPassword">
        <div className="title">Reset Users Password</div>
        <div className="instructions"></div>
        <div className="text">
          New User Password:{" "}
          <input
            type="text"
            onChange={(e) => onPasswordFieldChange(e)}
            value={resetPassword}
          />
        </div>

        <button className="saveBtn" onClick={() => execPasswordReset()}>
          Reset User Password
        </button>
        <button onClick={() => closePasswordReset()}>Cancel</button>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {};

export default ResetPassword;
