import {
  PREFLIGHT_FORM_LOADING,
  PREFLIGHT_FORM_ERROR,
  PREFLIGHT_FORM_SUCCESS,
  PREFLIGHT_FORM_RESET,
  PREFLIGHT_TABLE_LOADING,
  PREFLIGHT_TABLE_RESULT,
} from "../actions/types";

const initialState = {
  formLoading: false,
  formError: "",
  formSuccess: false,
  formSuccessID: "",
  tableLoading: false,
  tableData: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PREFLIGHT_FORM_LOADING:
      return {
        ...state,
        formLoading: true,
        formError: "",
        formSuccess: false,
        formSuccessID: "",
      };
    case PREFLIGHT_FORM_ERROR:
      return {
        ...state,
        formLoading: false,
        formError: payload,
        formSuccess: false,
        formSuccessID: "",
      };
    case PREFLIGHT_FORM_SUCCESS:
      return {
        ...state,
        formLoading: false,
        formError: "",
        formSuccess: true,
        formSuccessID: payload,
      };
    case PREFLIGHT_FORM_RESET:
      return {
        ...state,
        formError: "",
        formSuccess: false,
        formSuccessID: "",
      };
    case PREFLIGHT_TABLE_LOADING:
      return {
        ...state,
        tableLoading: true,
        tableData: [],
      };
    case PREFLIGHT_TABLE_RESULT:
      return {
        ...state,
        tableLoading: false,
        tableData: payload,
      };
    default:
      return state;
  }
}
