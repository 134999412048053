export const htmlSpecialsDecode = (str = '') => {
  str = str.replace('u+0002f', '/');
  str = str.replace('U+0002F', '/');
  return str;
};

export const htmlSpecialsEncode = (str = '') => {
  str = str.replace('/', 'U+0002F');
  str = str.replace('/', 'u+0002f');
  return str;
};

export const htmlSpecialsDecodeForSplitting = (str = '') => {
  str = str.replace('U+0002F', ',/,');
  return str;
};

export const htmlSpecialsEncodeForSplitting = (str = '') => {
  str = str.replace(',/,', 'U+0002F');
  return str;
};

export const isEven = (value) => {
  if (value % 2 === 0) return true;
  else return false;
};
