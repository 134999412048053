import {
  //REGISTER_SUCCESS,
  //REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_PROFILE,
  USER_RESETCODELOADING,
  USER_RESETCODEVALID,
  USER_RESETCODEINVALID,
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  msg: '',
  user: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };
    case GET_PROFILE:
      return {
        ...state,
        loading: false,
        user: payload,
      };
    //case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    //case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    case USER_RESETCODELOADING: {
      return {
        ...state,
        resetCodeLoading: true,
        resetCodeNotValid: false,
        resetCodeChecked: false,
      };
    }
    case USER_RESETCODEVALID:
      return {
        ...state,
        resetCodeLoading: false,
        resetCodeNotValid: false,
        resetCodeChecked: true,
      };
    case USER_RESETCODEINVALID:
      return {
        ...state,
        resetCodeLoading: false,
        resetCodeNotValid: true,
        resetCodeChecked: true,
      };
    default:
      return state;
  }
}
