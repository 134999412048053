import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import { v4 as uuidv4 } from 'uuid';
import Spinner from '../../../layout/Spinner';
import { formatMoney } from '../../../utils/currencyFormat';
import { isEven } from '../../../utils/generalUtils';

import miniLoading from './miniLoading.svg';
import ProdFuzzyMatchSingleProdDetails from './ProdFuzzyMatch_singleProdDetails';

import './Pagination.css';

const ProdFuzzyMatch_singleProdResults = ({
  resultsObj = {},
  resultsP21 = {},
  onMouseUpSingleSearchResultItem,
  searchFeedbackOnClick,
  searchAnalytics = {},
  onPaginationClick,
}) => {
  const { p21Info = {} } = resultsP21;
  const {
    loading = true,
    searchResultsArr = [],
    searchTerm = '',
    searchPartNumber = '',
    pag = 1,
    limit = 20,
    productCountTotal = 0,
  } = resultsObj;

  const { analyticsSubmitted = false } = searchAnalytics;

  if (searchTerm === '' && searchPartNumber === '') {
    return null;
  }

  return (
    <div className='singleProdSearchResult-outer'>
      <div className='searchProd_title-outer'>
        <div className='secTitle'>
          Product Search Suggestions For: <br />"{searchPartNumber}
          {searchPartNumber !== '' && searchTerm !== '' && ' '}
          {searchTerm}"
        </div>
        <div className='secFeedback'>
          {!analyticsSubmitted && (
            <Fragment>
              Did you find a good match?
              <div className='secFeedback-float'>
                <i
                  className='far fa-thumbs-up'
                  onClick={(e) => searchFeedbackOnClick(true)}
                />
                <i
                  className='far fa-thumbs-down'
                  onClick={(e) => searchFeedbackOnClick(false)}
                />
              </div>
            </Fragment>
          )}
          {analyticsSubmitted && (
            <Fragment>Thank you for your feedback...</Fragment>
          )}
        </div>
      </div>

      <div className='resultTable-outer'>
        <table className='tableDisplay' cellPadding='0' cellSpacing='0'>
          <thead>
            <tr>
              <td valign='top'>Part Number</td>
              <td valign='top'>P21 Description</td>
              <td valign='top' style={{ textAlign: 'center' }}>
                <span className='mobileOnly'>Qty</span>
                <span className='desktopOnly'>Qty On Hand</span>
              </td>
              <td
                valign='top'
                style={{ textAlign: 'center' }}
                className='desktopOnlyCell'
              >
                STARS / AD
              </td>
              <td valign='top' className='desktopOnlyCell'>
                Manufacturer
              </td>
            </tr>
          </thead>
          <tbody>
            {!loading && searchResultsArr.length === 0 && (
              <tr>
                <td colSpan='5'>No Products Found</td>
                <td></td>
              </tr>
            )}
            {!loading &&
              searchResultsArr.length > 0 &&
              searchResultsArr.map((row, index) => {
                const {
                  _id = '',
                  item_id = '',
                  description = '',
                  ad = false,
                  stars = false,
                  inv_mast_uid = '',
                  manufacturer_name = '',
                  manu_part_number = '',
                  //_score = 0,
                } = row;

                return (
                  <Fragment key={uuidv4()}>
                    <tr
                      onMouseUp={(e) =>
                        onMouseUpSingleSearchResultItem(
                          e,
                          item_id,
                          inv_mast_uid,
                          _id,
                          false
                        )
                      }
                      className={isEven(index) ? 'odd' : 'even'}
                    >
                      <td>
                        {item_id !== '' && item_id}
                        {item_id === '' && (
                          <div style={{ opacity: '.8' }}>
                            <i>MFG #:</i> {manu_part_number}
                          </div>
                        )}
                      </td>
                      <td>{description}</td>
                      {inv_mast_uid === null || inv_mast_uid === '' ? (
                        <Fragment>
                          <td style={{ textAlign: 'center' }}>-</td>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {p21Info !== null &&
                          p21Info !== undefined &&
                          p21Info[inv_mast_uid] !== undefined &&
                          p21Info[inv_mast_uid] !== null &&
                          p21Info[inv_mast_uid].hasOwnProperty(
                            'qty_on_hand'
                          ) ? (
                            <Fragment>
                              {p21Info[inv_mast_uid]['qty_on_hand'] > 0 ? (
                                <td style={{ textAlign: 'center' }}>
                                  {formatMoney(
                                    p21Info[inv_mast_uid]['qty_on_hand'],
                                    0
                                  )}
                                  {p21Info[inv_mast_uid].hasOwnProperty(
                                    'uom'
                                  ) && (
                                    <Fragment>
                                      {' '}
                                      {p21Info[inv_mast_uid].uom}
                                    </Fragment>
                                  )}
                                </td>
                              ) : (
                                <td
                                  style={{ opacity: '.4', textAlign: 'center' }}
                                >
                                  {formatMoney(
                                    p21Info[inv_mast_uid]['qty_on_hand'],
                                    0
                                  )}
                                  {p21Info[inv_mast_uid].hasOwnProperty(
                                    'uom'
                                  ) && (
                                    <Fragment>
                                      {' '}
                                      {p21Info[inv_mast_uid].uom}
                                    </Fragment>
                                  )}
                                </td>
                              )}
                            </Fragment>
                          ) : (
                            <td style={{ textAlign: 'center' }}>
                              <img src={miniLoading} alt='' />
                            </td>
                          )}
                        </Fragment>
                      )}

                      <td
                        style={{ textAlign: 'center' }}
                        className='desktopOnlyCell'
                      >
                        {stars && ad && <span>STARS + AD</span>}
                        {stars && !ad && <span>STARS</span>}
                        {!stars && ad && <span>AD</span>}
                      </td>
                      <td className='desktopOnlyCell'>
                        {manufacturer_name !== null
                          ? manufacturer_name.replace(/\b\w/g, (c) =>
                              c.toUpperCase()
                            )
                          : manufacturer_name}
                      </td>
                    </tr>

                    {p21Info !== null &&
                      p21Info !== undefined &&
                      p21Info[inv_mast_uid] !== undefined &&
                      p21Info[inv_mast_uid] !== null &&
                      p21Info[inv_mast_uid].hasOwnProperty(
                        'pricingDisplay'
                      ) && (
                        <tr
                          className={
                            isEven(index)
                              ? 'odd desktopOnlyRow'
                              : 'even desktopOnlyRow'
                          }
                        >
                          <td colSpan='5'>
                            {p21Info[inv_mast_uid].pricingLoading ? (
                              <div className='pricingLoading blinking'>
                                Loading
                              </div>
                            ) : (
                              <ProdFuzzyMatchSingleProdDetails
                                elasticInfo={row}
                                p21Info={p21Info[inv_mast_uid]}
                              />
                            )}
                          </td>
                        </tr>
                      )}
                  </Fragment>
                );
              })}
          </tbody>
        </table>

        {!loading &&
          searchResultsArr.length !== 0 &&
          productCountTotal > limit && (
            <div className='paginationDiv'>
              <Pagination
                prevPageText='prev'
                nextPageText='next'
                firstPageText='first'
                lastPageText='last'
                activePage={parseInt(pag)}
                itemsCountPerPage={limit}
                totalItemsCount={productCountTotal}
                onChange={onPaginationClick}
              />
            </div>
          )}
        {loading && <Spinner />}
      </div>
    </div>
  );
};

ProdFuzzyMatch_singleProdResults.propTypes = {
  resultsObj: PropTypes.object.isRequired,
  onMouseUpSingleSearchResultItem: PropTypes.func.isRequired,
  searchFeedbackOnClick: PropTypes.func.isRequired,
  searchAnalytics: PropTypes.object.isRequired,
  onPaginationClick: PropTypes.func.isRequired,
};

export default ProdFuzzyMatch_singleProdResults;
