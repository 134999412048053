import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Spinner from '../../../layout/Spinner';

const ProdFuzzyMatch_singleOverlay_sec4 = ({ search_state = {} }) => {
  const {
    emp_prodfuzzy_fulltext_p21Info = {},
    emp_prodfuzzy_fulltext_productdetails = {},
  } = search_state;
  const { p21Data = {} } = emp_prodfuzzy_fulltext_productdetails;

  const { inv_mast_uid = '' } = p21Data;
  const { availabilityByLocation = [], pricingLoading = true } = assignVars(
    inv_mast_uid,
    emp_prodfuzzy_fulltext_p21Info
  );

  return (
    <div className='sec-outer'>
      <div className='secTitle'>Product Stock Availability</div>
      <div className='secContent'>
        {inv_mast_uid === '' && (
          <div style={{ paddingLeft: '10px', marginTop: '10px' }}>
            <i>Not currently in P21 / Stellar Inventory</i>
          </div>
        )}
        {inv_mast_uid !== '' && pricingLoading && <Spinner />}
        {inv_mast_uid !== '' && !pricingLoading && (
          <div className='item_availability'>
            {availabilityByLocation.length > 0 &&
              availabilityByLocation.map((location) => {
                const { locationName = '', quantity = 0 } = location;
                return (
                  <div className='loc-outer' key={uuidv4()}>
                    <div className='loc-qty'>{quantity}</div>
                    <div className='loc-name'>{locationName}</div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

const assignVars = (inv_mast_uid = '', emp_prodfuzzy_fulltext_p21Info = {}) => {
  if (
    inv_mast_uid !== '' &&
    emp_prodfuzzy_fulltext_p21Info !== null &&
    emp_prodfuzzy_fulltext_p21Info.p21Info !== undefined &&
    emp_prodfuzzy_fulltext_p21Info.p21Info !== null &&
    emp_prodfuzzy_fulltext_p21Info.p21Info[inv_mast_uid] !== undefined &&
    emp_prodfuzzy_fulltext_p21Info.p21Info[inv_mast_uid] !== null
  ) {
    return emp_prodfuzzy_fulltext_p21Info.p21Info[inv_mast_uid];
  }
  return {};
};

ProdFuzzyMatch_singleOverlay_sec4.propTypes = {
  search_state: PropTypes.object.isRequired,
};

export default ProdFuzzyMatch_singleOverlay_sec4;
