import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

import Spinner from '../../../layout/Spinner';
import instruction1 from './instruction1.jpg';
import instruction2 from './instruction2.jpg';

const ProdFuzzyMatchBulkMatch = ({
  bulkFormData,
  bulkFormError = '',
  onChangeInputBulk,
  submitBulkFuzzy,
  errorBulkFuzzy,
  onClickDeleteBulkFuzzyProject,
  bulkFuzzyMatchState = {},
  onChangeBulk_CompanyIDAsYouType,
  emp_bulkFuzzyMatch_company,
  onClickBulkAsYouTypeDiv_CompanyID,
  onChangeBulk_ShipToAsYouType,
  emp_bulkFuzzyMatch_shipto,
  onClickBulkAsYouTypeDiv_ShipToID,
}) => {
  const {
    name = '',
    filePath = '',
    priceBulkResults = '0',
    bulkCustomerID = '',
    bulkCustomerName = '',
    bulkShipToID = '',
    bulkShipToName = '',
  } = bulkFormData;
  const {
    formLoading = false,
    formError = '',
    formSuccessID = '',
    tableLoading = false,
    tableData = [],
  } = bulkFuzzyMatchState;

  const {
    asYouTypeArray: asYouTypeArray_companyBulk = [],
    showAsYouType: showAsYouType_companyBulk = false,
  } = emp_bulkFuzzyMatch_company;

  const {
    asYouTypeArray: asYouTypeArray_shiptoBulk = [],
    showAsYouType: showAsYouType_shiptoBulk = false,
  } = emp_bulkFuzzyMatch_shipto;

  const onBulkSubmit = (e) => {
    e.preventDefault();

    let clearToSubmit = true;
    if (name === undefined || name === null || name === '') {
      errorBulkFuzzy('Please enter a project name to continue');
      clearToSubmit = false;
    }

    if (
      ref.current.files === undefined ||
      ref.current.files === null ||
      ref.current.files[0] === undefined
    ) {
      errorBulkFuzzy('Please select a CSV file to upload');
      clearToSubmit = false;
    }

    if (
      priceBulkResults === '1' &&
      (bulkCustomerID === null ||
        bulkCustomerID === '' ||
        bulkShipToID === null ||
        bulkShipToID === '')
    ) {
      errorBulkFuzzy('Please enter a Customer ID and a Ship To ID');
      clearToSubmit = false;
    }

    if (clearToSubmit) {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('file', ref.current.files[0]);
      formData.append('priceBulkResults', priceBulkResults);
      formData.append('bulkCustomerID', bulkCustomerID);
      formData.append('bulkShipToID', bulkShipToID);

      submitBulkFuzzy(formData);
    }
  };

  const ref = React.createRef();

  return (
    <div className='content'>
      <div className='flex'>
        <div className='leftDiv'>
          <div className='secTitle' style={{ marginBottom: '0px' }}>
            Upload a Spreadsheet of Products for ID Assist:
          </div>
          <div className='marginBottom'>
            In order to use ID Assist download and use this{' '}
            <a
              href={process.env.PUBLIC_URL + '/resources/searchTemplate.csv'}
              target='_blank'
              rel='noopener noreferrer'
            >
              template spreadsheet.
            </a>{' '}
            <br />
            <br />* Uploaded product suggestion spreadsheets deleted after 60
            days
          </div>

          <Fragment>
            <div
              className={
                formLoading ? 'bulkForm formLoadingOpacity' : 'bulkForm'
              }
            >
              <form
                name='bulkUploadFuzzyMatchForm'
                onSubmit={(e) => onBulkSubmit(e)}
              >
                <div className='marginBottom'>
                  <label name='name'>Name Of Your Project:</label>
                  <br />
                  <input
                    type='text'
                    placeholder='Project Name'
                    name='name'
                    value={name}
                    onChange={(e) => onChangeInputBulk(e)}
                  />
                </div>
                <div className='marginBottom'>
                  <label name='filePath'>To Upload Excel CSV File:</label>
                  <br />
                  <b style={{ color: 'red' }}>Note:</b> Do not change column
                  names, or columns will be ignored during search.
                  <br />
                  <input
                    type='file'
                    placeholder='Select CSV File'
                    value={filePath}
                    name='filePath'
                    accept='.csv'
                    onChange={(e) => onChangeInputBulk(e)}
                    ref={ref}
                  />
                </div>
                <div className='bulkPricing'>
                  <div style={{ marginBottom: '10px' }}>
                    Price Spreadsheet Results:{' '}
                    <select
                      name='priceBulkResults'
                      value={priceBulkResults}
                      onChange={(e) => onChangeInputBulk(e)}
                    >
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select>
                    {priceBulkResults === '1' && (
                      <Fragment>
                        <br />
                        <b>Note:</b> Pricing can add <b>considerable</b>{' '}
                        processing time to your product matching. Please allow
                        up to 30 seconds per item being searched (ie: 120 items
                        = 1 hour of processing).
                      </Fragment>
                    )}
                  </div>
                  {priceBulkResults === '1' && (
                    <Fragment>
                      <div className='bulkPricing-inner-item'>
                        <label name='bulkCustomerID'>CustomerID:</label>
                        <input
                          type='text'
                          name='bulkCustomerID'
                          value={bulkCustomerID}
                          onChange={(e) => onChangeBulk_CompanyIDAsYouType(e)}
                          style={{ width: '60px' }}
                          autoComplete='off'
                        />
                        <input
                          type='text'
                          name='bulkCustomerName'
                          value={bulkCustomerName}
                          className='fullWidth'
                          onChange={(e) => onChangeBulk_CompanyIDAsYouType(e)}
                          autoComplete='off'
                        />
                      </div>
                      <div
                        className={
                          showAsYouType_companyBulk
                            ? 'searchAsTypeAnchor-outer'
                            : 'searchAsTypeAnchor-outer hide'
                        }
                      >
                        {asYouTypeArray_companyBulk &&
                          asYouTypeArray_companyBulk.length > 0 && (
                            <div className='searchAsTypeAnchor'>
                              {Object.keys(asYouTypeArray_companyBulk).map(
                                (keyName) => {
                                  const {
                                    companyName = '',
                                    p21_companyID = '',
                                  } = asYouTypeArray_companyBulk[keyName];
                                  return (
                                    <div
                                      key={uuidv4()}
                                      onClick={(e) =>
                                        onClickBulkAsYouTypeDiv_CompanyID(
                                          companyName,
                                          p21_companyID
                                        )
                                      }
                                    >
                                      {p21_companyID} - {companyName}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                      </div>

                      <div className='bulkPricing-inner-item'>
                        <label name='bulkShipToID'>ShipToID:</label>
                        <input
                          type='text'
                          name='bulkShipToID'
                          value={bulkShipToID}
                          onChange={(e) => onChangeBulk_ShipToAsYouType(e)}
                          onClick={(e) => onChangeBulk_ShipToAsYouType(e)}
                          style={{ width: '60px' }}
                          autoComplete='off'
                        />
                        <input
                          type='text'
                          name='bulkShipToName'
                          value={bulkShipToName}
                          onChange={(e) => onChangeBulk_ShipToAsYouType(e)}
                          onClick={(e) => onChangeBulk_ShipToAsYouType(e)}
                          className='fullWidth'
                          autoComplete='off'
                        />
                      </div>
                      <div
                        className={
                          showAsYouType_shiptoBulk
                            ? 'searchAsTypeAnchor-outer'
                            : 'searchAsTypeAnchor-outer hide'
                        }
                      >
                        {asYouTypeArray_shiptoBulk &&
                          asYouTypeArray_shiptoBulk.length > 0 && (
                            <div className='searchAsTypeAnchor'>
                              {Object.keys(asYouTypeArray_shiptoBulk).map(
                                (keyName) => {
                                  const {
                                    shipToName = '',
                                    p21_shipToID = '',
                                  } = asYouTypeArray_shiptoBulk[keyName];
                                  return (
                                    <div
                                      key={uuidv4()}
                                      onClick={(e) =>
                                        onClickBulkAsYouTypeDiv_ShipToID(
                                          shipToName,
                                          p21_shipToID
                                        )
                                      }
                                    >
                                      {p21_shipToID} - {shipToName}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                      </div>
                    </Fragment>
                  )}
                </div>
                {formError !== '' && (
                  <div className='sumbitErrorMsg'>{formError}</div>
                )}
                <input
                  type='submit'
                  name='submit'
                  value='Upload CSV File As Project Resource'
                />
              </form>
            </div>
            <div
              className={
                formLoading
                  ? 'formLoadingSpinner-outer'
                  : 'formLoadingSpinner-outer hidden'
              }
            >
              <Spinner />
            </div>
          </Fragment>

          {!formLoading && formSuccessID !== '' && (
            <div className='blinking'>
              <h3
                style={{
                  color: 'green',
                  textAlign: 'center',
                  marginBlockEnd: '5px',
                }}
              >
                Your Project Has Been Uploaded Successfully
              </h3>
              <h4 style={{ textAlign: 'center', marginBlockStart: '5px' }}>
                Please see below for results.
              </h4>
            </div>
          )}
          <div className='recentProjectDocuments-outer'>
            <h3>Project ID Suggestion Results Spreadsheets:</h3>
            {tableLoading ? (
              <Spinner />
            ) : (
              <table
                className='tableDisplay'
                width='100%'
                cellPadding='0'
                cellSpacing='0'
              >
                <tbody>
                  {tableData.length > 0 &&
                    tableData.map((project) => {
                      const {
                        projectName = '',
                        date = new Date.now(),
                        status = 'processing',
                        _id = '',
                      } = project;

                      return (
                        <tr key={uuidv4()}>
                          <td valign='top'>
                            {<Moment format='MM/DD/YYYY'>{date}</Moment>}
                          </td>
                          <td valign='top'>{projectName}</td>
                          <td valign='top'>
                            {status === 'processed' ? (
                              <Fragment>
                                <a
                                  href={`/api/bulkProdFuzzyMatch/${_id}/true`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                  Hard Matches Only
                                </a>
                                {' / '}
                                <a
                                  href={`/api/bulkProdFuzzyMatch/${_id}/false`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                  Fuzzy Matches Only
                                </a>
                              </Fragment>
                            ) : (
                              <span className='blinking'>Processing</span>
                            )}
                            {' / '}{' '}
                            <span
                              className='deleteProject'
                              onClick={(e) => onClickDeleteBulkFuzzyProject(e)}
                              data-id={_id}
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  {tableData.length === 0 && (
                    <tr>
                      <td valign='top'>No Previous Projects Found</td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className='rightDiv'>
          <h3>Spreadsheet Prep Instructions:</h3>
          <div>
            <p>
              <b>1.</b>{' '}
              <a
                href={process.env.PUBLIC_URL + '/resources/searchTemplate.csv'}
                target='_blank'
                rel='noopener noreferrer'
              >
                Download Bulk Search Template File
              </a>{' '}
              and fill in all known product search information.
            </p>
            <img src={instruction1} alt='Instruction 1' />
          </div>
          <div>
            <p>
              <b>2.</b> In Excel go to File {'>'} Save As {'>'} Change File type
              to CSV (Comma Delimited), then save the file for upload.
            </p>
            <img src={instruction2} alt='Instruction 2' />
          </div>

          <div>
            <p>
              <b style={{ color: 'red' }}>Note:</b> Do not change column names.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

/*

          {!formLoading && formSuccessID !== '' && (
            <div className='formSuccessDiv'>
              <div className='leftDiv'>
                <i className='fas fa-server fa-7x'></i>
              </div>
              <div className='rightDiv'>
                <div className='title'>Your Project Is Being Processed</div>
                <div className='subTitle'>Results Will Be Available Below</div>
                <div className='subsubTitle'>
                  (May take up to 10 minutes to process large files)
                </div>
                <br />
                <div
                  className='clearBulkFuzzySuccessForm'
                  onClick={(e) => onClickResetBulkFuzzy(e)}
                >
                  Upload another file
                </div>
              </div>
            </div>
          )}
          */

ProdFuzzyMatchBulkMatch.propTypes = {
  bulkFormData: PropTypes.object.isRequired,
  bulkFormError: PropTypes.string.isRequired,
  onChangeInputBulk: PropTypes.func.isRequired,
  bulkFuzzyMatchState: PropTypes.object.isRequired,
  onClickDeleteBulkFuzzyProject: PropTypes.func.isRequired,
  onChangeBulk_CompanyIDAsYouType: PropTypes.func.isRequired,
  emp_bulkFuzzyMatch_company: PropTypes.object.isRequired,
  onClickBulkAsYouTypeDiv_CompanyID: PropTypes.func.isRequired,
  onChangeBulk_ShipToAsYouType: PropTypes.func.isRequired,
  emp_bulkFuzzyMatch_shipto: PropTypes.object.isRequired,
  onClickBulkAsYouTypeDiv_ShipToID: PropTypes.func.isRequired,
};

export default ProdFuzzyMatchBulkMatch;
